import React from 'react';
import Loadable from 'react-loadable';

const loading = () => <React.Fragment />;

const asyncRoute = (getComponent) => {
  const component = Loadable({
    loader: getComponent,
    loading,
  });
  // setTimeout(() => {
  component.preload();
  // }, 5000);
  return component;
};

export default asyncRoute;
