import { isEqual, isEmpty, filter, set, slice, forEachRight, get, cloneDeep } from 'lodash';
import { detect } from 'detect-browser';

import { constants } from '../constants/configuration';

import { highAvailabilityServerAddress, httpProtocol } from '../constants/globals';
import { currencies } from 'config';

// helper methods
export const replaceAt = (string = '', index, replacement) => {
  return string.substr(0, index) + replacement + string.substr(index + replacement.length);
};

export const getPageWidth = () => {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth,
  );
};

export const getPriceInr = (price) => `₹ ${price}`;

export const drawLine = (canvas, begin, end, color) => {
  canvas.beginPath();
  canvas.moveTo(begin.x, begin.y);
  canvas.lineTo(end.x, end.y);
  canvas.lineWidth = 4;
  canvas.strokeStyle = color;
  canvas.stroke();
};

// get color based on index
export const getColorIndex = (index) => {
  return index % 2 ? '#f5f5f5' : '#fff';
};

export const scrollElementIntoView = (id) => {
  const element = document.querySelector(`#${id}`);
  element && element.scrollIntoView({ block: 'end' });
};

export const itemQuantityOptions = (quantity) => {
  let optionsArr = [];
  for (let option = 1; option <= quantity; option++) {
    optionsArr.push({ key: `${option + 1}`, text: `${renderCount(option + 1)}`, value: option });
  }
  return optionsArr;
};

export const customStyles = {
  content: {
    top: '60%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export const imageStyle = {
  icon: {
    height: '24px',
    width: '24px',
  },
  search: {
    height: '30px',
    width: '24px',
  },
};

export const weekdays = [
  {
    text: 'SUN',
    value: 'SUNDAY',
  },
  {
    text: 'MON',
    value: 'MONDAY',
  },
  {
    text: 'TUE',
    value: 'TUESDAY',
  },
  {
    text: 'WED',
    value: 'WEDNESDAY',
  },
  {
    text: 'THU',
    value: 'THURSDAY',
  },
  {
    text: 'FRI',
    value: 'FRIDAY',
  },
  {
    text: 'SAT',
    value: 'SATURDAY',
  },
];

export const getTableLabel = (type, tableLabel) => {
  let label = {
    SP: tableLabel,
    DI: `${constants.tableType.table} ${tableLabel}`,
    DT: tableLabel,
    RO: tableLabel,
    DL: tableLabel,
  };
  return label[type];
};

export const renderOrderStatus = (tableType, status) => {
  if (isEqual(tableType, 'DL')) {
    switch (status) {
      case 'READY':
        return 'OUT FOR DELIVERY';
      case 'SETTLED':
        return 'DELIVERED';
      default:
        return status;
    }
  } else {
    return status;
  }
};

/**
 *
 * @param {Array} array Collection of objects containing key
 * @param {String} keyword Partial value (substring) to search for within object's 'key' values
 * @param {String} objectKey Objects's key to search corresponding values for
 *
 * @return {Array} Returns the array containing matching objects
 */
export const searchKeywordInArrayByKey = (array, keyword, objectKey) => {
  return filter(array, (object) => object[objectKey].indexOf(keyword) > -1);
};

// Format address lines to comma separated title case text
export const formatCompleteAddress = ({
  addressLine1,
  landmark,
  localityName,
  localitySecondaryText,
  title,
}) => {
  const addressParts = [addressLine1, landmark, localityName, localitySecondaryText].filter(
    (part) => part && part.length,
  );
  const formattedAddress = addressParts.join(', ');
  if (addressParts.length === 0) {
    return '';
  } else if (!title) {
    return formattedAddress;
  } else {
    return `${title} - ${formattedAddress}`;
  }
};

/* Credits: To Title Case © 2018 David Gouch | https://github.com/gouch/to-title-case */
// Converts a string to Title Case
// eslint-disable-next-line no-extend-native
export const toTitleCase = (string) => {
  const smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i;
  const alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/;
  const wordSeparators = /([ :–—-])/;

  return string
    .split(wordSeparators)
    .map(function (current, index, array) {
      if (
        /* Check for small words */
        current.search(smallWords) > -1 &&
        /* Skip first and last word */
        index !== 0 &&
        index !== array.length - 1 &&
        /* Ignore title end and subtitle start */
        array[index - 3] !== ':' &&
        array[index + 1] !== ':' &&
        /* Ignore small words that start a hyphenated phrase */
        (array[index + 1] !== '-' || (array[index - 1] === '-' && array[index + 1] === '-'))
      ) {
        return current.toLowerCase();
      }

      /* Ignore intentional capitalization */
      if (current.substr(1).search(/[A-Z]|\../) > -1) {
        return current;
      }

      /* Ignore URLs */
      if (array[index + 1] === ':' && array[index + 2] !== '') {
        return current;
      }

      /* Capitalize the first letter */
      return current.replace(alphanumericPattern, function (match) {
        return match.toUpperCase();
      });
    })
    .join('');
};

/**
 * Recursively get all paths to the key in an object
 * @param {object} obj Object|Array|Collection to deep search key in
 * @param {string} key Key to be searched
 * @param {any} value Value stored in the key
 * @param {*} prev Previous path relative to obj to prefix to found path which would be used to trace further paths
 */
export const getAllPaths = (obj, key, value, prev = '') => {
  const result = [];

  for (let objKey in obj) {
    let path = prev + (prev ? '.' : '') + objKey;

    if (objKey === key && obj[objKey] === value) {
      result.push(path);
    } else if (typeof obj[objKey] == 'object') {
      result.push(...getAllPaths(obj[objKey], key, value, path));
    }
  }

  return result;
};

/**
 * Reorders a list of items returning an array.
 * @param {any} list Enumerable list of items
 * @param {Number} startIndex Source index
 * @param {Number} endIndex Destination index
 */
export const reorderList = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const prepareReorderedItemListReqBody = (items, sourceIndex, destinationIndex) => {
  items = reorderList(items, sourceIndex, destinationIndex); // Returns an array with reordered items
  let updatableItems = [];
  // If dragged item was positioned before it's drop position (destiantion)
  if (sourceIndex < destinationIndex) {
    // Extract menu items whose order has changed after dragging
    updatableItems = slice(cloneDeep(items), sourceIndex, destinationIndex);

    // Decrease every updatable item's index by 1 as it has moved one place up in order
    forEachRight(updatableItems, (item, index, itemList) => {
      if (index !== 0) {
        set(
          item,
          'sequenceNumber',
          get(itemList[index - 1], 'sequenceNumber', 0), // Assign preceeding item's sequenceNumber
        );
      } else {
        // For first item, just set to dragged item's original sequence number
        set(item, 'sequenceNumber', get(items[destinationIndex], 'sequenceNumber', 0));
      }
    });
    // Update sequence number of the dragged item
    set(
      items[destinationIndex],
      'sequenceNumber',
      get(items[destinationIndex - 1], 'sequenceNumber', 0), // Assign preceeding item's sequenceNumber
    );
    updatableItems.push(items[destinationIndex]);
  } else {
    updatableItems = slice(cloneDeep(items), destinationIndex + 1, sourceIndex + 1);
    // Increase every updatable item's index by 1 as it has moved one place down in order
    updatableItems.forEach((item, index, itemList) => {
      if (index !== itemList.length - 1) {
        set(
          item,
          'sequenceNumber',
          get(itemList[index + 1], 'sequenceNumber', 0), // Assign succeeding item's sequenceNumber
        );
      } else {
        // For last item, just set to dragged item's original sequence number
        set(item, 'sequenceNumber', get(items[destinationIndex], 'sequenceNumber', 0));
      }
    });
    // Update sequence number of the dragged item
    set(
      items[destinationIndex],
      'sequenceNumber',
      get(items[destinationIndex + 1], 'sequenceNumber', 0), // Assign succeeding item's sequenceNumber
    );
    updatableItems.push(items[destinationIndex]);
  }
  return updatableItems;
};

/**
 * Sorts an object by its keys based on a compare function (optional)
 * @param {object} unsortedObject Unsorted object
 * @param {Function} [compareFunction] Optional callback which provides a compare function to serve as the basis for sort, default sort based on ASCII comparator
 *
 * @return {object} sortedObject Sorted object
 */
export const sortObjectByKeysUsingComparator = (unsortedObject, compareFunction) => {
  let sortedObject = {};
  Object.keys(unsortedObject)
    .sort(compareFunction)
    .forEach(function (key) {
      sortedObject[key] = unsortedObject[key];
    });

  return sortedObject;
};

export const renderCount = (count) => {
  switch (count) {
    case 1:
      return `${count}st`;
    case 2:
      return `${count}nd`;
    case 3:
      return `${count}rd`;
    default:
      return `${count}th`;
  }
};

/**
 * Returns an object containing browser name and version along with additional info about the user agent running the webapp
 * @returns {Object} Browser info
 */
export const detectWebAppBrowser = () => detect();

/**
 * function to calculate total amount sum
 * @param {*} collection
 * @param {*} decimal
 */
export const getTotalAmountSum = (collection, decimal, fieldName) => {
  let roundOff = Math.pow(10, decimal);
  let sum = collection.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue[!isEmpty(fieldName) ? fieldName : 'totalAmount'],
    0,
  );
  return Math.round(sum * roundOff) / roundOff;
};

/**
 * Generates a universally unique id
 */
export const generateUUID = () => {
  let basex = 16;
  let d = new Date().getTime();
  if (window.performance && typeof window.performance.now === 'function') {
    d += performance.now(); // Use high-precision timer if available
  }
  let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * basex) % basex | 0;
    d = Math.floor(d / basex);
    let sr = 0x3;
    let srx = r & sr;
    let srx1 = 0x8;
    return (c === 'x' ? r : srx | srx1).toString(basex);
  });
  return uuid;
};

/**
 * Rounds off the given value upto 'precision' places of decimal
 *
 * @param {Number} value Numeric value to be rounded off
 * @param {Number} precision Places after dot to round off upto
 */
export const roundOff = (value, precision = 0) => {
  let precisionMultiplier = Math.pow(10, precision);
  return Math.round((value + Number.EPSILON) * precisionMultiplier) / precisionMultiplier; // Number.EPSILON offset helps round off figures ending in 5 like 0.05, 1.145, uniformly across all browsers
};

/**
 * Checks if the user-agent is online by using Navigator API and pinging a highly available server on the internet
 */
export const checkInternetConnectivity = () => {
  if (!navigator.onLine) {
    // This indicates that the user-agent is definitely offline
    const userOnlinePromise = new Promise((resolve, reject) => {
      resolve(false);
    });
    return userOnlinePromise;
  } else {
    // Try pinging a highly available server to double check if the user-agent is able to access the internet
    return pingServer(highAvailabilityServerAddress).then((isAvailable) => isAvailable);
  }
};

/**
 * Ping a server existing at the passed server address
 * @param {String} serverAddress A server url or dns
 * @returns {Promise} Resolution of promise returns a boolean indicating if the hit on the server was successful
 */
export const pingServer = async (serverAddress) => {
  if (!serverAddress) {
    return false;
  }

  try {
    await fetch(serverAddress, { mode: 'no-cors' }); // Fetch trial, if successful, will continue here instead of jumping to catch
    return true;
  } catch (error) {
    return false;
  }
};

export const getPercentageValueOfPrice = (percentage, value) => {
  const calculatedValue = roundOff((percentage * value) / 100, 2);
  return calculatedValue;
};

export const constructURL = (url) =>
  !isEmpty(url) && !isEqual(url.substring(0, 4), httpProtocol) ? httpProtocol + '://' + url : url;

export const isIOS = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform,
    ) ||
    // iPad on iOS 13+ detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const getCustomPaginationProps = (paginationProps) => {
  let { currentPage, prevPage, nextPage, pageLinks, paginationObj, defaultPageUrl } =
    paginationProps;
  if (isEqual(get(paginationObj, 'totalPages'), 1)) {
    defaultPageUrl = get(pageLinks, 'self.href', '');
  } else if (currentPage === nextPage) {
    defaultPageUrl = get(pageLinks, 'next.href', '');
  } else if (currentPage === prevPage) {
    defaultPageUrl = get(pageLinks, 'prev.href', '');
  } else if (currentPage === get(paginationObj, 'totalPages') - 1) {
    defaultPageUrl = get(pageLinks, 'last.href', '');
  } else if (currentPage === 0) {
    defaultPageUrl = get(pageLinks, 'first.href', '');
  } else {
    defaultPageUrl = get(pageLinks, 'self.href', '');
  }

  nextPage = currentPage + 1;
  prevPage = currentPage - 1;
  return { defaultPageUrl, currentPage, prevPage, nextPage };
};

export const printStyle = () =>
  `<style type="text/css" media="print">
        @page
        {
            size: auto;  /* auto is the initial value */
            margin: 0mm;  /* this affects the margin in the printer settings */
        }
        html
        {
            background-color: #ffffff;
            padding: 0 6px 12px 6px;  /* this affects the margin on the html before sending to printer */
        }
        .footer {
            break-after: page;
        }
    </style>`;

export const getTransactionTypeTitle = (
  transactionTypeCode,
  transactionSubCategoryMasterDataArray = [],
) => {
  const matchingTransactionSubCategoryObj =
    transactionSubCategoryMasterDataArray.find(
      (transactionSubCategory) => get(transactionSubCategory, 'code', '') === transactionTypeCode,
    ) || {};

  return get(matchingTransactionSubCategoryObj, 'title', '');
};

export const getTransactionStatusTitle = (
  transactionStatusCode,
  transactionStatusMasterDataArray = [],
) => {
  const matchingTransactionStatusObj =
    transactionStatusMasterDataArray.find(
      (transactionStatus) => get(transactionStatus, 'code', '') === transactionStatusCode,
    ) || {};

  return get(matchingTransactionStatusObj, 'title', '');
};

export const getTransactionCategoryTitle = (
  transactionCatCode,
  transactionCatMasterDataArray = [],
) => {
  const matchingTransactionCatObj =
    transactionCatMasterDataArray.find(
      (transactionCat) => get(transactionCat, 'code', '') === transactionCatCode,
    ) || {};

  return get(matchingTransactionCatObj, 'title', 'Any');
};

export const getFilterString = (appliedFilterObj) => {
  let filterString = '';
  if (!isEmpty(appliedFilterObj)) {
    const appliedFilter = Object.keys(appliedFilterObj);
    if (appliedFilter.length > 0) {
      appliedFilter.forEach((filterKey) => {
        if (!isEmpty(appliedFilterObj[filterKey])) {
          filterString = `${filterString}&${filterKey}=${appliedFilterObj[filterKey]}`;
        }
      });
    }
  }
  return filterString;
};

/**
 * Sort a collection by an integer-value key of the objects in the collection. This methods sorts the collection in-place as well.
 * Ensures that Infinity, -Infinity, NaN do not interfere with the main sequence sort even if they occur unexpectedly. They are pushed to the end of the collection.
 *
 * @param {Array} collection Collection to be sorted
 * @param {String | Number} numericKey Integer-value holding key
 * @returns Sorted collection
 */
export const sortCollectionByNumber = (collection, numericKey) => {
  collection?.sort((object1, object2) => {
    const number1 = parseInt(object1[numericKey]);
    const number2 = parseInt(object2[numericKey]);

    if (isFinite(number1 - number2)) {
      return number1 - number2;
    } else {
      return isFinite(number1) ? -1 : 1; // Sort -Infinity, NaN, Infinity to the end in random order
    }
  });
};

export const degreesToRadians = (degrees) => {
  return (degrees * Math.PI) / 180;
};

export const radiansToDegrees = (radians) => {
  return radians * (180 / Math.PI);
};

/**
 *
 * @param {Float} latitude1
 * @param {Float} longitude1
 * @param {Float} latitude2
 * @param {Float} longitude2
 * @returns distance between the coordinates
 */
export const checkDistanceBetweenCoordinates = ({
  latitude1,
  longitude1,
  latitude2,
  longitude2,
}) => {
  if (latitude1 == latitude2 && longitude1 == longitude2) {
    return 0;
  } else {
    const theta = longitude1 - longitude2;
    let distance =
      Math.sin(degreesToRadians(latitude1)) * Math.sin(degreesToRadians(latitude2)) +
      Math.cos(degreesToRadians(latitude1)) *
        Math.cos(degreesToRadians(latitude2)) *
        Math.cos(degreesToRadians(theta));
    distance = Math.acos(distance);
    distance = radiansToDegrees(distance);
    // There are 60 nautical miles per degree separation of longitudes.
    distance = distance * 60 * 1.1515; //factor to convert nautical miles into regular mile
    distance = distance * 1.609344; // km factor
    return distance;
  }
};

/**
 * Return the difference between two dates as the number of days. The order of the dates passed as arguments does not matter.
 * @param {Date} date1
 * @param {Date} date2
 * @returns {Number} Number of days between the two dates
 */
export const calculateDifferenceInDates = (date1, date2) => {
  // Calculate total number of seconds between two dates
  const totalSeconds = Math.abs(date2 - date1) / 1000;

  // Calculate days difference by dividing total seconds in a day
  const daysDifference = Math.ceil(totalSeconds / (60 * 60 * 24));

  return daysDifference;
};

export const bindScrollOfElements = (element1, element2) => {
  element1.onscroll = (event) => (element2.scrollLeft = event?.target?.scrollLeft);
};

// As long as the callback continues to be invoked, it will not be triggered
export const debounce = (func, delay = 200, immediate = false) => {
  let timeout;

  return function () {
    const context = this,
      args = arguments;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, delay);
    if (callNow) func.apply(context, args);
  };
};

// As long as the callback continues to be invoked, raise on every interval
export const throttle = (func, interval = 200, immediate = false) => {
  let timeout = null;
  let initialCall = true;

  return function () {
    const context = this,
      args = arguments;
    const callNow = immediate && initialCall;
    const later = () => {
      func.apply(context, args);
      timeout = null;
    };

    if (callNow) {
      initialCall = false;
      later();
    }

    if (!timeout) {
      timeout = setTimeout(later, interval);
    }
  };
};

export const formatAmountWithCurrency = (amount, prefix = currencies.INR.symbol) => {
  return `${prefix} ${amount}`;
};

/**
 * Format an array of strings ['a', 'b', 'c'] in the form 'a, b and c'
 * @param {array} itemsArray
 * @param {boolean} useAmpersand
 * @returns {string} formatted string
 */
export const formatCommaSeparatedWithTrailingAnd = (itemsArray = [], useAmpersand = false) => {
  if (itemsArray?.length < 1) return '';

  if (itemsArray?.length === 1) return itemsArray.toString();

  return [...itemsArray.slice(0, itemsArray.length - 1)]
    .join(', ')
    .concat(` ${useAmpersand ? '&' : 'and'} ${itemsArray[itemsArray.length - 1]}`);
};

/**
 * Delay the execution of a block of code by ms milliseconds
 *
 * @param {*} ms
 * @returns
 */
export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Sort a collection alphabetically by object key
 *
 * @param {Array} collection
 * @param {String} key
 */
export const sortCollectionAlphabetically = (collection, key) => {
  return collection.sort((a, b) => {
    const nameA = a[key]?.toUpperCase(); // ignore upper and lowercase
    const nameB = b[key]?.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });
};

/**
 * Returns a promise resolving into a byte string of the converted image
 * @param {*} imageURL image data URL
 * @param {*} resizeCommand ImageMagick command
 * @returns {Promise} Promise resolving into a Byte String
 */
export const processImageWithMagick = async (imageURL, resizeCommand) => {
  try {
    // Build an input file by fetching its content
    const fetchedSourceImage = await fetch(imageURL);
    const content = new Uint8Array(await fetchedSourceImage.arrayBuffer());
    const inputImage = { name: 'inputImage.png', content };

    if (!window.magick) throw 'ImageMagick not available';

    // Resize the image as per the OS type requested
    const processedFiles = await window.magick.Call([inputImage], resizeCommand);
    const [iconFile] = processedFiles;
    const iconBufferArray = iconFile.buffer; // Get buffer array of the file processed by image magick
    let byteString = '';
    // Extract raw string chars from buffer array
    const STRING_CHAR = iconBufferArray.reduce((data, byte) => {
      return data + String.fromCharCode(byte);
    }, '');
    byteString = btoa(STRING_CHAR); // Convert string chars into base64 encoded string

    return byteString;
  } catch (error) {
    console.error(`Encountered an error while converting the image: ${error}`);
  }
};

export const isUUID = (value) => {
  const uuidPattern =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return uuidPattern.test(value);
};
