const routes = {
  restaurant: 'restaurant',
  containers: {
    orders: 'orders',
    menu: 'menu',
    history: 'history',
    guests: 'guests',
    offers: 'offers',
    settings: 'settings',
    upsell: 'upsell',
    cashCard: 'cash-card',
    account: 'account',
    integrations: 'integrations',
  },
  menu: {
    menuItems: 'menu-items',
    customizations: 'customizations',
  },
  offers: {
    offerList: 'offer-list',
    archivedOffers: 'archived-offers',
  },
  cashCard: {
    list: 'list',
    transaction: 'transactions',
  },
  integrations: {
    pos: 'pos',
  },
  pos: {
    syncLogs: 'sync-logs',
  },
};

export default routes;
