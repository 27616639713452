// fudr level reducer

import { SUPER_ADMIN_SIGN_IN_SUCCESS } from 'actions/authentication/actionTypes';

import {
  FETCH_RESTAURANT_DETAILS_BY_SCAN_CODE,
  CLEAR_RESTAURANT_DETAILS,
  FETCH_RESTAURANT_POS_INTEGRATIONS,
  CLEAR_RESTAURANT_POS_INTEGRATIONS,
  FETCH_GUEST_SEGMENTS_DATA,
  CREATE_SUGGEST_CAMPAIGN,
  FETCH_SUGGESTED_CAMPAIGNS,
  UPDATE_SUGGEST_CAMPAIGN,
  FETCH_ALL_BRANDS,
  CLEAR_ALL_BRANDS,
  FETCH_SUGGESTED_CAMPAIGN_BRANDS,
  CLEAR_SUGGESTED_CAMPAIGN_BRANDS,
  FETCH_CAMPAIGN_BY_ID,
  CLEAR_SUGGEST_CAMPAIGN,
  FETCH_BRANDS_SUMMARY,
  FETCH_DISTRIBUTION_PLANS,
  FETCH_DISTRIBUTION_TEMPLATES,
} from 'actions/fudr/actionTypes';

const initialState = {};

const fudrReducer = (state = initialState, action) => {
  switch (action?.type) {
    case SUPER_ADMIN_SIGN_IN_SUCCESS:
      const { authData } = action;
      return {
        ...state,
        authData,
      };
    case FETCH_RESTAURANT_DETAILS_BY_SCAN_CODE:
      const { restaurant } = action;
      return {
        ...state,
        restaurant,
      };
    case CLEAR_RESTAURANT_DETAILS:
      return {
        ...state,
        restaurant: {},
      };
    case FETCH_RESTAURANT_POS_INTEGRATIONS:
      const { posIntegrationProperties } = action;
      return {
        ...state,
        pos: posIntegrationProperties,
      };
    case CLEAR_RESTAURANT_POS_INTEGRATIONS:
      return {
        ...state,
        pos: {},
      };
    case FETCH_GUEST_SEGMENTS_DATA:
      const { guestSegments } = action;
      return {
        ...state,
        guestSegments,
      };
    case CREATE_SUGGEST_CAMPAIGN:
    case UPDATE_SUGGEST_CAMPAIGN:
    case FETCH_CAMPAIGN_BY_ID:
      const { suggestCampaign } = action;
      return {
        ...state,
        suggestCampaign,
      };
    case CLEAR_SUGGEST_CAMPAIGN:
      return {
        ...state,
        suggestCampaign: {},
      };
    case FETCH_SUGGESTED_CAMPAIGNS:
      const { suggestedCampaigns } = action;
      return {
        ...state,
        suggestedCampaigns,
      };
    case CLEAR_SUGGESTED_CAMPAIGN_BRANDS:
      return {
        ...state,
        suggestedCampaignBrands: {},
      };
    case FETCH_ALL_BRANDS:
      const { allBrands } = action;
      return {
        ...state,
        allBrands,
      };
    case CLEAR_ALL_BRANDS:
      return {
        ...state,
        allBrands: {},
      };
    case FETCH_SUGGESTED_CAMPAIGN_BRANDS:
      const { suggestedCampaignBrands } = action;
      return {
        ...state,
        suggestedCampaignBrands,
      };
    case FETCH_BRANDS_SUMMARY:
      const { brands } = action;
      return {
        ...state,
        brands,
      };
    case FETCH_DISTRIBUTION_PLANS:
      const { distributionPlans } = action;
      return {
        ...state,
        distributionPlans,
      };
    case FETCH_DISTRIBUTION_TEMPLATES:
      const { distributionTemplates } = action;
      return {
        ...state,
        distributionTemplates,
      };

    default:
      return state;
  }
};

export default fudrReducer;
