const routes = {
  authentication: 'authentication',
  containers: {
    signIn: 'signin',
    signUp: 'signup',
    pseudoSignIn: 'pseudo-signin',
    resetPassword: 'reset-password',
    fudrAdminSignIn: 'fudr-signin',
  },
};

export default routes;
