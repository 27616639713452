// added namespacing to avoid interference with other plugins or redux stores

export const FETCH_GUEST_ORDERS = 'FETCH_GUEST_ORDERS';

export const UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS';

export const UPDATE_GUEST_ORDER = 'UPDATE_GUEST_ORDER'; // This action type will be used to declare all actions that update guest order, including updating items.

export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';

export const CHECK_GUEST_ORDERS = 'CHECK_GUEST_ORDERS';

export const BEGIN_NOTIFICATION_LOADER = 'BEGIN_NOTIFICATION_LOADER';

export const END_NOTIFICATION_LOADER = 'END_NOTIFICATION_LOADER';

export const SAVE_CURRENT_TIME = 'SAVE_CURRENT_TIME';

export const FETCH_ORDER_HISTORY = 'FETCH_ORDER_HISTORY';

export const GET_ORDER_BY_ID_SUCCESS = 'GET_ORDER_BY_ID_SUCCESS';

export const GET_ORDER_BY_ID_FAILURE = 'GET_ORDER_BY_ID_FAILURE';

export const UPDATE_ORDER = 'UPDATE_ORDER'; // Currently, this action type is only meant to update order items. To be engulfed by UPDATE_GUEST_ORDER in future.

export const GET_RESTAURANT_MENU = 'GET_RESTAURANT_MENU';

export const GET_MENU_CUSTOMIZATIONS = 'GET_MENU_CUSTOMIZATIONS';

export const FETCH_MENU_ITEM_CUSTOMIZATIONS = 'FETCH_MENU_ITEM_CUSTOMIZATIONS';

export const TIMER_INITIATED = 'TIMER_INITIATED';

export const DISPATCH_ASSISTANCE_TIME_STAMP = 'DISPATCH_ASSISTANCE_TIME_STAMP';

export const GET_GUESTS_LIST = 'GET_GUESTS_LIST';

export const GET_GUEST_DETAILS = 'GET_GUEST_DETAILS';

export const GET_GUEST_ORDERS = 'GET_GUEST_ORDERS';

export const DISPATCH_RESTAURANT_SETTINGS = 'DISPATCH_RESTAURANT_SETTINGS';

export const CLEAR_RESTAURANT_SETTINGS = 'CLEAR_RESTAURANT_SETTINGS';

export const UPDATE_RESTAURANT_ASSISTANCE_SERVICES = 'UPDATE_RESTAURANT_ASSISTANCE_SERVICES';

export const GET_CURRENT_ASSISTANCES = 'GET_CURRENT_ASSISTANCES';

export const DISPATCH_ATTENDED_ASSISTANCES = 'DISPATCH_ATTENDED_ASSISTANCES';

export const GET_ORDER_SUMMARY_DATA = 'GET_ORDER_SUMMARY_DATA';

export const FETCH_OFFERS = 'FETCH_OFFERS';

export const DISPATCH_OFFER_LIST = 'DISPATCH_OFFER_LIST';

export const OFFER_DATA = 'OFFER_DATA';

export const FETCH_MENU_CATEGORIES = 'FETCH_MENU_CATEGORIES';

export const GET_LOCALITIES_BY_CITY = 'GET_LOCALITIES_BY_CITY';

export const API_ERROR_RESPONSE = 'API_ERROR_RESPONSE';

export const FETCH_RESTAURANT_TABLES = 'FETCH_RESTAURANT_TABLES';

export const UPDATE_RESTAURANT_ADDITIONAL_CHARGES = 'UPDATE_RESTAURANT_ADDITIONAL_CHARGES';

export const ADD_RESTAURANT_ADDITIONAL_CHARGES = 'ADD_RESTAURANT_ADDITIONAL_CHARGES';

export const DELETE_RESTAURANT_ADDITIONAL_CHARGES = 'DELETE_RESTAURANT_ADDITIONAL_CHARGES';

export const UPDATE_RESTAURANT_NOTIFICATION_EMAILS = 'UPDATE_RESTAURANT_NOTIFICATION_EMAILS';

export const FETCH_OFFER_GUESTS = 'FETCH_OFFER_GUESTS';

export const GET_UPSELL_ITEM = 'GET_UPSELL_ITEM';

export const GET_RESTAURANT_UPSELL = 'GET_RESTAURANT_UPSELL';

export const FETCH_RESTAURANT_CASH_CARD = 'FETCH_RESTAURANT_CASH_CARD';

export const FETCH_CARD_LIST = 'FETCH_CARD_LIST';

export const CLEAR_CARD_LIST = 'CLEAR_CARD_LIST';

export const FETCH_CARD_DETAILS = 'FETCH_CARD_DETAILS';

export const CLEAR_CARD_DETAILS = 'CLEAR_CARD_DETAILS';

export const FETCH_GUEST_CASH_CARD_TRANSACTIONS = 'FETCH_GUEST_CASH_CARD_TRANSACTIONS';

export const CLEAR_GUEST_CASH_CARD_TRANSACTIONS = 'CLEAR_GUEST_CASH_CARD_TRANSACTIONS';

export const FETCH_ORDER_CANCELLATION_REASONS = 'FETCH_ORDER_CANCELLATION_REASONS';

export const CLEAR_ORDER_CANCELLATION_REASONS = 'CLEAR_ORDER_CANCELLATION_REASONS';

export const FETCH_RESTAURANT_SUBSCRIPTION_DETAILS = 'FETCH_RESTAURANT_SUBSCRIPTION_DETAILS';

export const CLEAR_RESTAURANT_SUBSCRIPTION_DETAILS = 'CLEAR_RESTAURANT_SUBSCRIPTION_DETAILS';

export const FETCH_RESTAURANT_SUBSCRIPTION_PLAN_HISTORY =
  'FETCH_RESTAURANT_SUBSCRIPTION_PLAN_HISTORY';

export const CLEAR_RESTAURANT_SUBSCRIPTION_PLAN_HISTORY =
  'CLEAR_RESTAURANT_SUBSCRIPTION_PLAN_HISTORY';

export const FETCH_SAAS_PLANS = `FETCH_SAAS_PLANS`;

export const DISPATCH_PROGRESSIVE_WEB_APP_DATA = 'DISPATCH_PROGRESSIVE_WEB_APP_DATA';

export const CLEAR_PROGRESSIVE_WEB_APP_DATA = 'CLEAR_PROGRESSIVE_WEB_APP_DATA';

export const PURGE = 'persist/PURGE'; // Action type for redux store persistor purging (purging means clearing)

export const GET_ENTITY_MAPPING_DATA = 'GET_ENTITY_MAPPING_DATA';

export const UPDATE_ENTITY_MAPPING_DATA = 'UPDATE_ENTITY_MAPPING_DATA';

export const CLEAR_ENTITY_MAPPING_DATA = 'CLEAR_ENTITY_MAPPING_DATA';

export const FETCH_POS_SYNC_STATUS_BY_ORDER_IDS = 'FETCH_POS_SYNC_STATUS_BY_ORDER_IDS';

export const CLEAR_POS_SYNC_STATUS_BY_ORDER_IDS = 'CLEAR_POS_SYNC_STATUS_BY_ORDER_IDS';

export const DISPATCH_POS_SYNC_STATUS = 'DISPATCH_POS_SYNC_STATUS';

export const GET_RESTAURANT_POS_INTEGRATIONS = 'GET_RESTAURANT_POS_INTEGRATIONS';

export const ClEAR_RESTAURANT_POS_INTEGRATIONS = 'ClEAR_RESTAURANT_POS_INTEGRATIONS';

export const CLEAR_RESTAURANT_INTEGRATIONS = 'CLEAR_RESTAURANT_INTEGRATIONS';

export const FETCH_BULK_POS_SYNC_EVENTS = 'FETCH_BULK_POS_SYNC_EVENTS';

export const CLEAR_BULK_POS_SYNC_EVENTS = 'CLEAR_BULK_POS_SYNC_EVENTS';

export const FETCH_EXTERNAL_POS_MENU_ITEMS = 'FETCH_EXTERNAL_POS_MENU_ITEMS';

// Engage Specific action types

export const REDEEMED_DEAL_DATA = 'REDEEMED_DEAL_DATA';

export const VALIDATE_DEAL_DATA = 'VALIDATE_DEAL_DATA';
