export const FETCH_RESTAURANT_DETAILS_BY_SCAN_CODE = 'FETCH_RESTAURANT_DETAILS_BY_SCAN_CODE';

export const CLEAR_RESTAURANT_DETAILS = 'CLEAR_RESTAURANT_DETAILS';

export const FETCH_RESTAURANT_POS_INTEGRATIONS = 'FETCH_RESTAURANT_POS_INTEGRATIONS';

export const CLEAR_RESTAURANT_POS_INTEGRATIONS = 'CLEAR_RESTAURANT_POS_INTEGRATIONS';

export const UPDATE_POS_SYNC_STATUS = 'UPDATE_POS_SYNC_STATUS';

export const FETCH_GUEST_SEGMENTS_DATA = 'FETCH_GUEST_SEGMENTS_DATA';

export const CREATE_SUGGEST_CAMPAIGN = 'CREATE_SUGGEST_CAMPAIGN';

export const FETCH_SUGGESTED_CAMPAIGNS = 'FETCH_SUGGESTED_CAMPAIGNS';

export const UPDATE_SUGGEST_CAMPAIGN = 'UPDATE_SUGGEST_CAMPAIGN';

export const FETCH_ALL_BRANDS = 'FETCH_ALL_BRANDS';

export const CLEAR_ALL_BRANDS = 'CLEAR_ALL_BRANDS';

export const FETCH_SUGGESTED_CAMPAIGN_BRANDS = 'FETCH_SUGGESTED_CAMPAIGN_BRANDS';

export const CLEAR_SUGGESTED_CAMPAIGN_BRANDS = 'CLEAR_SUGGESTED_CAMPAIGN_BRANDS';

// export const SUGGEST_CAMPAIGN_TO_BRANDS = 'SUGGEST_CAMPAIGN_TO_BRANDS';

export const FETCH_CAMPAIGN_BY_ID = 'FETCH_CAMPAIGN_BY_ID';

export const CLEAR_SUGGEST_CAMPAIGN = 'CLEAR_SUGGEST_CAMPAIGN';

export const FETCH_BRANDS_SUMMARY = 'FETCH_BRANDS_SUMMARY';

export const FETCH_DISTRIBUTION_PLANS = 'FETCH_DISTRIBUTION_PLANS';

export const FETCH_DISTRIBUTION_TEMPLATES = 'FETCH_DISTRIBUTION_TEMPLATES';
