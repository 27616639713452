import client from '../utils/client';
import Axios from 'axios';
import base64 from 'base-64';
import { restaurantDataDepthParams } from '../config';
import restaurantRoutes from '../routes/restaurant';

// all the apis can be called from here

export const createRestaurantDashboardApi = ({ formData, accessToken }) => {
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  return client().post('/restaurants', formData);
};

export const fetchMasterDataApi = ({ appName }) => {
  return client().get(`/lookup/appSettings/${appName}`);
};

export const fetchStatesApi = () => {
  return client().get('/lookup/states');
};

export const fetchCitiesByStateApi = ({ stateId }) => {
  return client().get(`/lookup/cities?stateId=${stateId}`);
};

export const getCurrentOrders = async (accessToken, restaurantId) => {
  try {
    Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
    const { data } = await client().post('/orders/current', { restaurantId });
    return data;
  } catch (error) {
    // get axios errors from error.response
    throw error;
  }
};

export const checkCurrentOrders = async (accessToken, restaurantId) => {
  let response = {};
  try {
    Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
    response = await client().get(`/orders/checkcurrent?restaurantId=${restaurantId}`);
  } catch (error) {
    // get axios errors from error.response
    console.error(error);
  }
  return response.data;
};

export const updateOrderStatusApi = async (orderUpdateReqBody) => {
  try {
    const { data } = await client().put('/orders/status', orderUpdateReqBody);
    return data;
  } catch (error) {
    // get axios errors from error.response
    throw error;
  }
};

export const updateGuestOrderApi = (orderId, orderUpdateType, updateGuestOrderReqBody) =>
  client().put(`/orders/${orderId}?type=${orderUpdateType}`, updateGuestOrderReqBody);

export const updateTableApi = ({ updateTableReqBody, orderId }) =>
  client().put(`/orders/updateTable/${orderId}`, updateTableReqBody);

export const initiateSignInApi = (signInReqBody) => {
  const { username, password } = signInReqBody;
  const basicToken = base64.encode(`${username}:${password}`);
  try {
    Axios.defaults.headers['Authorization'] = `Basic ${basicToken}`;
    return client().post('/restaurants/admin/signin');
  } catch (error) {
    // get axios errors from error.response
    throw error;
  }
};

export const getOrderHistory = async ({ accessToken, url }) => {
  try {
    Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
    const { data } = await client().get(url);
    return data;
  } catch (error) {
    // get axios errors from error.response
    throw error;
  }
};

export const getOrderById = async (accessToken, orderId) => {
  try {
    Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
    return client().get(`/orders/${orderId}`);
  } catch (error) {
    // get axios errors from error.response
    throw error;
  }
};

export const getRestaurantMenuApi = ({ restaurantId }) =>
  client().get(`/restaurants/${restaurantId}/menu`);

export const fetchMenuCategoryDetailsApi = ({ categoryId }) =>
  client().get(`/menucategories/${categoryId}`);

export const createMenuCategoriesApi = ({ createMenuCategoryReqBody }) =>
  client().post('/menucategories', createMenuCategoryReqBody);

export const updateMenuCategoriesApi = ({ updateMenuCategoryReqBody }) =>
  client().put('/menucategories', updateMenuCategoryReqBody);

export const deleteMenuCategoryApi = ({ accessToken, categoryId }) => {
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  return client().delete(`/menucategories/${categoryId}`);
};

export const deleteMenuItemApi = ({ accessToken, menuItemId }) => {
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  return client().delete(`/menus/items/${menuItemId}`);
};

export const updateMenuItemApi = ({ updateMenuItemReqBody }) =>
  client().put('/menus/items', updateMenuItemReqBody);

export const createMenuItemApi = ({ createMenuItemReqBody }) =>
  client().post('/menus/items', createMenuItemReqBody);

export const fetchMenuItemDetailsApi = ({ menuItemId }) =>
  client().get(`/menus/items/${menuItemId}`);

export const fetchCustomizedItemDetailsApi = ({ customizedItemId }) =>
  client().get(`/customizations/${customizedItemId}`);

export const createCustomizedGroupApi = ({ customizedGroupReqBody }) =>
  client().post('/customizations/groups', customizedGroupReqBody);

export const createCustomizedGroupItemApi = ({ customizedGroupItemReqBody }) =>
  client().post('/customizations', customizedGroupItemReqBody);

export const editCustomizedGroupApi = ({ customizedGroupReqBody }) =>
  client().put('/customizations/groups', customizedGroupReqBody);

export const editCustomizedGroupItemApi = ({ customizedGroupItemReqBody }) =>
  client().put('/customizations', customizedGroupItemReqBody);

export const deleteCustomizedItemApi = ({ customizedItemId, accessToken }) => {
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  return client().delete(`/customizations/${customizedItemId}`);
};

export const deleteCustomizedGroupApi = ({ customizedGroupId, accessToken }) => {
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  return client().delete(`/customizations/groups/${customizedGroupId}`);
};

export const getMenuCustomizationsApi = ({ restaurantId }) =>
  client().get(`/customizations/groups?restaurantId=${restaurantId}`);

export const updateMenuCustomizationsApi = ({ updateMenuCustomizationsReqBody }) =>
  client().put('/menus/customizations', updateMenuCustomizationsReqBody);

export const getGuestsListApi = async ({ accessToken, url }) => {
  try {
    Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
    const { data } = await client().get(url);
    return data;
  } catch (error) {
    // get axios errors from error.response
    throw error;
  }
};

export const getGuestDetailsApi = async ({ accessToken, restaurantId, guestId }) => {
  try {
    Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
    const { data } = await client().get(
      `/guests/details?guestId=${guestId}&restaurantIds=${restaurantId}`,
    );
    return data;
  } catch (error) {
    // get axios errors from error.response
    throw error;
  }
};

export const getGuestOrdersApi = async ({ accessToken, url }) => {
  try {
    Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
    const { data } = await client().get(url);
    return data;
  } catch (error) {
    // get axios errors from error.response
    throw error;
  }
};

export const updateRestaurantSettingsApi = ({ accessToken, updateRestaurantSettingsReqBody }) => {
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  return client().put(`/restaurants/settings`, updateRestaurantSettingsReqBody);
};

export const fetchAssistanceServicesMasterDataApi = ({ accessToken }) => {
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  return client().get('/assistances/masterdata');
};

export const updateRestaurantAssistanceServicesApi = ({
  accessToken,
  updateRestaurantAssistanceServicesReqBody,
}) => {
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  return client().post(
    '/assistances/manageAssistanceServices',
    updateRestaurantAssistanceServicesReqBody,
  );
};

export const getCurrentAssistancesApi = async ({ accessToken, assistancesReqBody }) => {
  try {
    Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
    const { data } = await client().post('/assistances/current', assistancesReqBody);
    return data;
  } catch (error) {
    // get axios errors from error.response
    throw error;
  }
};

export const attendAssistancesApi = ({ attendAssistancesReqBody }) =>
  client().post('/assistances/attend', attendAssistancesReqBody);

export const getRestaurantDataApi = ({
  restaurantId,
  accessToken,
  depthParam = restaurantDataDepthParams.default,
}) => {
  // setting default depthParam as all
  try {
    Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
    return client().get(`/restaurants/${restaurantId}?depth=${depthParam}`);
  } catch (error) {
    // get axios errors from error.response
    throw error;
  }
};

export const fetchRestaurantDetailsByScanCodeAPI = ({
  scanCode,
  depthParam = restaurantDataDepthParams.default,
}) => client().get(`/restaurants/scan/${scanCode}?depth=${depthParam}`);

export const getOrderSummaryDataApi = async ({ orderSummaryReqBody }) =>
  client().post('/restaurants/orderstats', orderSummaryReqBody);

export const fetchMenuItemCustomizationsApi = async ({ restaurantId, menuItemId }) => {
  try {
    const {
      data: { menuCustomizationGroups },
    } = await client().get(
      `/customizations/groups?restaurantId=${restaurantId}&menuItemId=${menuItemId}`,
    );
    return menuCustomizationGroups;
  } catch (error) {
    // get axios errors from error.response
    throw error;
  }
};

export const fetchOffersListApi = ({ accessToken, url }) => {
  try {
    Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
    return client().get(url);
  } catch (error) {
    // get axios errors from error.response
    throw error;
  }
};

export const createOfferApi = async ({ accessToken, offerParams }) => {
  const { reqBody } = offerParams;
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  return client().post('/offers', reqBody);
};

export const editOfferApi = async ({ accessToken, offerParams }) => {
  const { reqBody, offerId } = offerParams;
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  return client().put(`/offers/${offerId}`, reqBody);
};

export const deleteOfferApi = async ({ accessToken, offerParams }) => {
  const { offerId } = offerParams;
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  return client().delete(`/offers/${offerId}`);
};

export const validateOfferCodeApi = async ({ validateOfferCodeParams }) => {
  const { code, restaurantId } = validateOfferCodeParams;
  try {
    const { data } = await client().get(
      `/offers/checkCode?code=${code}&restaurantId=${restaurantId}`,
    );
    return data;
  } catch (error) {
    // get axios errors from error.response
    throw error;
  }
};

export const getOfferDetailsApi = async ({ accessToken, params }) => {
  const { offerId } = params;
  try {
    Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
    const { data } = await client().get(`/offers/${offerId}`);
    return data;
  } catch (error) {
    // get axios errors from error.response
    throw error;
  }
};

export const fetchMenuCategoriesApi = async ({ restaurantId }) =>
  client().get(`/menucategories/headers?restaurantId=${restaurantId}`);

/**
 * Fetch localities by city and search input from API
 *
 * @param {object} [cityId] City's identification key (*required)
 *                 [input] Search keyword input from user
 */
export const getLocalitiesByCityApi = ({ cityId }) =>
  client().get(`/lookup/localities?cityId=${cityId}`);

/**
 * Verifies if the input custom key is unique
 * Returns {data: true} in case the input key is unique and thus available, otherwise {data: false}
 *
 * @param {object} [customKey] Restaurant custom key/code
 */
export const verifyCustomKeyApi = ({ customKey, keyType }) =>
  client().post('/customLinks/checkKey', { key: customKey, type: keyType });

/**
 * Fetch custom links specific to a restaurant
 *
 * @param {object} [customLinkParams] Request body
 */
export const getCustomLinksApi = ({ customLinkParams, customLinkSearchUrl }) => {
  const { reqBody } = customLinkParams;
  return client().post(customLinkSearchUrl, reqBody);
};

/**
 * Create new custom link specific to a restaurant
 *
 * @param {object} [customLinkParams] Request body
 */
export const createCustomLinkApi = ({ customLinkParams }) => {
  const { reqBody } = customLinkParams;
  return client().post('/customLinks', reqBody);
};

/**
 * Fetch tax rates
 */
export const getTaxRatesApi = () => client().get('/lookup/taxrates');

/**
 *  Fetch restaurant tables
 *  accept parameter includeRunningOrders to fetch table running orders
 */
export const fetchRestaurantTablesApi = ({
  page,
  restaurantId,
  tableType,
  includeRunningOrders,
  qrCodeDefaultPageSize,
  defaultSort,
}) => {
  let url = '';
  switch (page) {
    case restaurantRoutes.containers.orders:
      url = `/restaurantTables?restaurantId=${restaurantId}&types=${tableType}&includeRunningOrders=${includeRunningOrders}&size=${qrCodeDefaultPageSize}&sort=${defaultSort}`;
      break;
    case restaurantRoutes.containers.settings:
      url = `/restaurantTables?restaurantId=${restaurantId}&size=${qrCodeDefaultPageSize}`;
      break;
    default:
      break;
  }
  return client().get(url);
};

/**
 * Update restaurant additional charges' details
 */
export const updateRestaurantAdditionalChargesApi = ({
  accessToken,
  updateRestaurantAdditionalChargesReqBody,
}) => {
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  return client().put('/additionalCharges/updateCharges', updateRestaurantAdditionalChargesReqBody);
};

/**
 * Add restaurant additional charge
 */
export const addRestaurantAdditionalChargesApi = ({
  accessToken,
  addRestaurantAdditionalChargesReqBody,
}) => {
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  return client().post('/additionalCharges/addCharges', addRestaurantAdditionalChargesReqBody);
};

/**
 * Delete restaurant additional charge
 */
export const deleteRestaurantAdditionalChargesApi = ({ accessToken, additionalChargeId }) => {
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  return client().delete(`/additionalCharges/${additionalChargeId}`);
};

/**
 * API to export Guest List
 * @param {*} accessToken
 * @param {*} exportGuestListReqBody
 */
export const exportGuestListApi = ({ accessToken, exportGuestListReqBody }) => {
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  return client().post('/reports/guestList', exportGuestListReqBody);
};

/**
 * API to export Order History List
 * @param {*} accessToken
 * @param {*} exportOrderHistoryReqBody
 */
export const exportOrderHistoryApi = ({ accessToken, exportOrderHistoryReqBody }) => {
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  return client().post('/reports/orders/v2', exportOrderHistoryReqBody);
};

/**
 * API to export Order History List
 * @param {*} accessToken
 * @param {*} exportOrderHistoryReqBody
 */
export const exportOrderItemsReportApi = ({ accessToken, exportOrderItemsReportReqBody }) => {
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  return client().post('/reports/orderItems', exportOrderItemsReportReqBody);
};

/**
 * API to fetch restaurant notification emails
 */
export const fetchRestaurantNotificationEmailsApi = (restaurantId) =>
  client().get(`/restaurants/notificationEmails?restaurantId=${restaurantId}`);

/**
 * API to add/update restaurant notification emails
 */
export const updateRestaurantNotificationEmailsApi = ({
  accessToken,
  updateRestaurantNotificationEmailsReqBody,
}) => {
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  return client().post(
    '/restaurants/manageNotificationEmails',
    updateRestaurantNotificationEmailsReqBody,
  );
};
/**
 * API ot verify restaurant username
 * @param {*} username
 */
export const verifyRestaurantUserName = ({ username }) =>
  client().get(`/restaurants/verifyUserId?userId=${username}`);

/**
 * API to sign up new restaurant
 * @param {*} signUpRestaurantReqBody
 */
export const signUpRestaurantApi = ({ signUpRestaurantReqBody }) =>
  client().post('/restaurants', signUpRestaurantReqBody);

export const fetchAllNotificationSoundsApi = () => client().get('/lookup/notificationSound');

export const fetchGuestListApi = async ({ accessToken, url, guestSearchReqBody }) => {
  try {
    Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
    const { data } = await client().post(url, guestSearchReqBody);
    return data;
  } catch (error) {
    // get axios errors from error.response
    throw error;
  }
};

export const fetchOfferGuestApi = async ({ accessToken, url }) => {
  try {
    Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
    const { data } = await client().get(url);
    return data;
  } catch (error) {
    // get axios errors from error.response
    throw error;
  }
};

export const fetchUpsellDiscountRangesApi = () => client().get('/lookup/upsellDiscountRanges');

export const creatUpsellItemApi = ({ upsellMenuItemObj }) =>
  client().post('/restaurantUpsells', upsellMenuItemObj);

export const getUpsellDataApi = async ({ url }) => {
  const { data } = await client().get(url);
  return data;
};

export const updateUpsellItemsApi = async ({ updatedUpsellItemList }) =>
  client().put('/restaurantUpsells', updatedUpsellItemList);

export const getRestaurantUpsellApi = async ({ restaurantId, includeItems }) =>
  client().get(`/restaurantUpsells?restaurantId=${restaurantId}&includeItems=${includeItems}`);

export const fetchRestaurantCashCardApi = async ({ restaurantId }) =>
  client().get(`/cashCards?restaurantId=${restaurantId}`);

export const createRestaurantCashCardApi = ({ restaurantCashCardObj }) =>
  client().post('/cashCards', restaurantCashCardObj);

export const updateRestaurantCashCardApi = ({ restaurantCashCardObj, cashCardId }) =>
  client().put(`/cashCards/${cashCardId}`, restaurantCashCardObj);

export const fetchCardListApi = ({ accessToken, url }) => {
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  return client().get(url);
};

export const fetchGuestCashCardDetailsApi = ({ accessToken, guestCashCardId }) => {
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  return client().get(`/cashCards/guests/${guestCashCardId}`);
};

export const fetchGuestCashCardTransactionsApi = ({ accessToken, url }) => {
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  return client().get(url);
};

export const checkFeaturesStatusApi = ({ restaurantId, featuresArr }) =>
  client().get(`/restaurants/checkFeatures?restaurantId=${restaurantId}&features=${featuresArr}`);

export const findGuestByPhoneNumberApi = ({ phoneNumber, restaurantId, depthParam }) =>
  client().get(
    `/guests/phoneSearch?phoneNum=${phoneNumber}&restaurantId=${restaurantId}&depth=${depthParam}&verified=true`,
  );

export const sendOtpApi = ({ phoneNumber, countryCode, res }) =>
  client().post('/guests/sendAuth', { phone: phoneNumber, country: countryCode, res });

export const createNewGuestApi = ({ createNewGuestReqBody }) =>
  client().post('/guests/signin', createNewGuestReqBody);

export const addNewGuestCashCardApi = ({ addNewGuestCashCardReqBody }) =>
  client().post('/cashCards/guests', addNewGuestCashCardReqBody);

export const rechargeGuestCashCardApi = ({ rechargeGuestCashCardReqBody }) =>
  client().post('/cashCards/addMoney', rechargeGuestCashCardReqBody);

export const updateGuestDetailsApi = ({ guestId, updateGuestDetailsReqBody }) =>
  client().put(`/guests/${guestId}`, updateGuestDetailsReqBody);

export const fetchOrderCancellationReasonsApi = ({ restaurantId }) =>
  client().get(`/restaurants/cancelReasons?restaurantId=${restaurantId}`);

export const fetchRestaurantSubscriptionApi = ({
  restaurantId,
  restaurantAttributesDepth,
  userGroupId,
}) =>
  client().get(
    `/subscriptions${restaurantId ? `?restaurantId=${restaurantId}` : ''}${
      !!restaurantAttributesDepth ? `&depth=${restaurantAttributesDepth}` : ''
    }${userGroupId ? `&userGroupId=${userGroupId}` : ''}`,
  );

export const fetchRestaurantSubscriptionHistoryApi = ({ subscriptionId }) =>
  client().get(`/subscriptions/${subscriptionId}/subscriptionPlans`);

export const fetchSaaSPlansApi = (includeActivePlansFlag = true) =>
  client().get(`/plans${includeActivePlansFlag ? '?includeActivePlans=true' : ''}`);

export const fetchProgressiveWebAppDataApi = ({ subDomain }) =>
  client().get(`/whiteLabelApps?sd=${subDomain}`);

export const createProgressiveWebAppApi = ({ progressiveWebAppReqBody }) =>
  client().post('/whiteLabelApps', progressiveWebAppReqBody);

export const updateProgressiveWebAppApi = ({ progressiveWebAppReqBody, appId }) =>
  client().put(`/whiteLabelApps/${appId}`, progressiveWebAppReqBody);

export const registerSubscriptionRequestApi = ({ requestBody }) =>
  client().post(`/subscriptions/registerSubscriptionRequest`, requestBody);

export const initiatePseudoSignInApi = (signInReqBody) => {
  const { username, password, restaurantCode } = signInReqBody;
  const basicToken = base64.encode(`${username}:${password}`);

  Axios.defaults.headers['Authorization'] = `Basic ${basicToken}`;
  return client().post('/restaurants/pseudo-signin', { restaurantCode });
};

export const goLiveRestaurantApi = ({ requestBody }) =>
  client().post('/restaurants/goLive', requestBody);

export const getEntityMappingDataApi = ({ restaurantCode, params }) =>
  client().get(`/externalSystems/entitymappings/${restaurantCode}?${params}`);

export const updateEntityMappingDataApi = ({ restaurantCode, requestBody }) =>
  client().post(`/externalSystems/entitymappings/${restaurantCode}`, requestBody);

export const fetchPOSSyncStatusByOrderIdsApi = ({ restaurantCode, orderIds }) =>
  client().post('/orders/posSyncStatus', { restaurantCode, orderIds });

export const getRestaurantIntegrationsApi = ({ restaurantCode }) =>
  client().get(`/externalSystems/mappings/${restaurantCode}`);

export const fetchBulkPOSSyncEventsApi = ({ url }) => client().get(url);

export const fetchExternalPOSMenuItemsApi = ({ restaurantCode, externalSystemId }) =>
  client().get(
    `/menus/items/externalSystems/${restaurantCode}?externalSystemId=${externalSystemId}`,
  );

// Engage specific API's

export const validateCouponApi = ({ validateCouponReqBody }) =>
  client().post('/deals/validateCoupon', validateCouponReqBody);

export const redeemCouponApi = ({ redeemCouponReqBody }) =>
  client().post('/deals/redeem', redeemCouponReqBody);

export const updatePOSSyncStatusAPI = ({
  restaurantCode,
  mappingId,
  syncStatus,
  restaurantId,
  accessToken,
}) => {
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;

  return client().put(`/externalSystems/mappings/${restaurantCode}/${mappingId}`, {
    status: syncStatus,
    restaurantId,
  });
};

export const initiateSuperAdminSignInApi = (signInReqBody) => {
  const { username, password } = signInReqBody;
  const basicToken = base64.encode(`${username}:${password}`);
  Axios.defaults.headers['Authorization'] = `Basic ${basicToken}`;

  return client().post('/restaurants/super-admin/signin');
};

export const fetchGuestSegmentsDataApi = () => client().get('/lookup/guestSegments');

export const createSuggestCampaignApi = ({ suggestCampaignReqBody }) =>
  client().post('/deals/templates', suggestCampaignReqBody);

export const fetchSuggestedCampaignsApi = ({ params }) =>
  client().get(`/deals/templates/suggestions?includeStats=true&${params}`);

export const updateSuggestCampaignApi = ({ suggestCampaignReqBody }) =>
  client().put('/deals/templates', suggestCampaignReqBody);

export const fetchAllBrandsApi = ({ page, size, requestBody }) =>
  client().post(`/brands/search?page=${page}&size=${size}`, requestBody);

export const fetchSuggestedCampaignBrandsApi = ({ page, size, templateIds }) =>
  client().get(`/deals/templates/brands?page=${page}&size=${size}&templateIds=${templateIds}`);

export const suggestCampaignToBrandsApi = ({ requestBody }) =>
  client().post(`/deals/templates/addBrands`, requestBody);

export const fetchCampaignByIdApi = ({ campaignId }) =>
  client().get(`/deals/templates/${campaignId}`);

export const fetchBrandsSummaryApi = (
  { searchStr, expireDays, expiringDays, page, size, sort = '' },
  url,
) =>
  client().get(
    url
      ? url
      : `/brands/summary?searchStr=${searchStr}&expiredDays=${expireDays ?? 7}&expiringDays=${
          expiringDays ?? 3
        }&page=${page}&size=${size}${sort}`,
  );

export const createBrandApi = ({ accessToken, requestBody }) => {
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  return client().post(`/brands/onboarding`, requestBody);
};

export const linkOutletsToBrandsApi = ({ accessToken, requestBody }) => {
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  return client().post(`/brands/addOutlets`, requestBody);
};

export const fetchDistributionPlansApi = () => client().get(`/distributionPlans`);

export const fetchDistributionTemplatesApi = () =>
  client().get(`/distribution/templates?channels=SMS&messageTypes=REMINDER,PROMO&size=100`);
