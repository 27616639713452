// index reducer, one reducer to rule them all

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';

// reducers import
import authenticationReducer from './authentication';
import fudrReducer from './fudr';
import restaurantReducer from './restaurant';
import restaurantGroupReducer from './restaurantGroup';
import commonReducer from './common';

const errorPersistConfig = {
  key: 'errorResponse',
  storage: sessionStorage,
  blacklist: ['errorResponse'], // Prevent app.errorResponse store slice from being persisted into session storage
};

const appReducers = combineReducers({
  authentication: authenticationReducer,
  fudr: fudrReducer,
  restaurant: persistReducer(errorPersistConfig, restaurantReducer),
  restaurantGroup: restaurantGroupReducer,
  common: commonReducer,
});

export default appReducers;
