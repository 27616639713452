export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';

export const END_AJAX_CALL = 'END_AJAX_CALL';

export const FETCH_MASTER_DATA = 'FETCH_MASTER_DATA';

export const FETCH_ASSISTANCE_SERVICES_MASTER_DATA = 'FETCH_ASSISTANCE_SERVICES_MASTER_DATA';

export const API_ERROR_RESPONSE = 'API_ERROR_RESPONSE';

export const DISPATCH_ALL_NOTIFICATION_SOUNDS = 'DISPATCH_ALL_NOTIFICATION_SOUNDS';

export const DISPATCH_CURRENT_TIME_STAMP = 'DISPATCH_CURRENT_TIME_STAMP';

export const DISPATCH_ASSISTANCE_TIME_STAMP = 'DISPATCH_ASSISTANCE_TIME_STAMP';

export const FETCH_UPSELL_DISCOUNT_RANGES = 'FETCH_UPSELL_DISCOUNT_RANGES';

export const FETCH_STATES = 'FETCH_STATES';

export const GET_TAXRATES = 'GET_TAXRATES';

export const MARK_PERSISTOR_FOR_PURGE = 'persist/MARK_PERSISTOR_FOR_PURGE';

export const PURGE = 'persist/PURGE'; // Action type for redux store persistor purging (purging means clearing)
