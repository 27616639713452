// calls all apis for ya

import Axios from 'axios';
import rootStore from '../store';
import { apiBaseUrl } from '../config';
import { constants } from '../constants/configuration';
import {
  internetDisconnectivityHandledAPIEndpointBlacklist,
  internetErrorResponseStoreDispatchDelay,
} from '../constants/globals';
import { apiErrorResponseAction } from '../actions/common';
import { checkInternetConnectivity } from './helpers';

const { store } = rootStore;

const TYPE_JSON = 'application/json';

export const buildAPIInstance = ({ contenttype = TYPE_JSON }) => {
  // wait for valid base url
  const objAxios = Axios.create({
    baseURL: apiBaseUrl,
    headers: {
      common: {
        Accept: TYPE_JSON,
        'Content-Type': contenttype,
        'x-source-type': 'admin',
      },
      post: {
        'Content-Type': TYPE_JSON,
      },
    },
  });

  objAxios.interceptors.request.use((config) => {
    if (!config.url) {
      return config;
    }

    const requestURLPart = config.url;
    const isRequestURLBlacklisted = internetDisconnectivityHandledAPIEndpointBlacklist.some(
      (endpoint) => requestURLPart.indexOf(endpoint) > -1,
    );
    if (!isRequestURLBlacklisted) {
      checkInternetConnectivity().then((isOnline) => {
        if (!isOnline) {
          // Generate a custom error response containing a user-friendly message to indicate internet disconnectivity.
          const errorResponse = {
            message: constants.globalErrorInfo.internetDisconnectivityErrorMessage,
          };
          // Jump over all updates to errorResponse slice in redux store, if any, and then dispatch this one.
          setTimeout(() => {
            store.dispatch(apiErrorResponseAction(errorResponse));
          }, internetErrorResponseStoreDispatchDelay);
        }
      });
    }

    return config;
  });

  return objAxios;
};

const client = (params = {}) => buildAPIInstance(params);

export default client;
