// common level reducer
import { get } from 'lodash';

import {
  BEGIN_AJAX_CALL,
  END_AJAX_CALL,
  API_ERROR_RESPONSE,
  DISPATCH_ALL_NOTIFICATION_SOUNDS,
  DISPATCH_CURRENT_TIME_STAMP,
  DISPATCH_ASSISTANCE_TIME_STAMP,
  FETCH_MASTER_DATA,
  FETCH_ASSISTANCE_SERVICES_MASTER_DATA,
  FETCH_UPSELL_DISCOUNT_RANGES,
  FETCH_STATES,
  GET_TAXRATES,
  MARK_PERSISTOR_FOR_PURGE,
  PURGE,
} from '../../actions/common/actionTypes';

const initialState = {
  persistorMarkedForPurge: false,
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case BEGIN_AJAX_CALL:
    case END_AJAX_CALL:
      const { showSpinner } = action;
      return {
        ...state,
        showSpinner,
      };
    case FETCH_MASTER_DATA:
      const { masterData } = action;
      return {
        ...state,
        masterData,
      };
    case FETCH_ASSISTANCE_SERVICES_MASTER_DATA:
      const { assistanceServices } = action;
      return {
        ...state,
        masterData: {
          ...get(state, 'masterData', {}),
          assistanceServices,
        },
      };
    case API_ERROR_RESPONSE:
      const { errorResponse } = action;
      return {
        ...state,
        errorResponse,
      };
    case DISPATCH_ALL_NOTIFICATION_SOUNDS:
      const { notificationSounds } = action;
      return {
        ...state,
        notificationSounds,
      };
    case DISPATCH_CURRENT_TIME_STAMP:
      const { currentTimeStamp } = action;
      return {
        ...state,
        currentTimeStamp,
      };
    case DISPATCH_ASSISTANCE_TIME_STAMP:
      const { assistanceTimeStamp } = action;
      return {
        ...state,
        assistanceTimeStamp,
      };
    case FETCH_UPSELL_DISCOUNT_RANGES:
      const { upsellDiscountRanges } = action;
      return {
        ...state,
        masterData: {
          ...get(state, 'masterData', {}),
          upsellDiscountRanges,
        },
      };
    case FETCH_STATES:
      const { states } = action;
      return {
        ...state,
        masterData: {
          ...get(state, 'masterData', {}),
          states,
        },
      };
    case GET_TAXRATES:
      const { taxRates } = action;
      return {
        ...state,
        masterData: {
          ...get(state, 'masterData', {}),
          taxRates,
        },
      };
    case MARK_PERSISTOR_FOR_PURGE:
      const { persistorMarkedForPurge } = action;
      return {
        ...state,
        persistorMarkedForPurge,
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};

export default commonReducer;
