export const customLinksGuideDocLink =
  'https://drive.google.com/file/d/1M9DF1PhXuHoDinLSTV1t_4hjV8ZFbP4v/view?usp=sharing';

export const constants = {
  helpAndSupport: {
    title: 'HELP & SUPPORT',
    bookTraining: 'Register For Training',
    viewTrainingVideos: 'View Training Videos',
    raiseRequest: 'Raise Request',
  },
  newUpdates: 'New Updates',
  notifications: {
    title: 'Fudr Order',
    body: 'New order received at',
  },
  readyStateTableTypes: ['DT', 'DL', 'SP'],
  orders: {
    defaultPolling: 5000,
    toAttendText: 'To Attend',
    toSettleText: 'To Settle / Deliver',
    viewSummary: 'VIEW SUMMARY',
    filterApplied: 'Filter Applied',
    settleOrderPopup: {
      paymentDone: 'Payment Done',
      paymentPending: 'Payment Pending',
      selfPickUp: {
        paymentDoneDescription: 'Please make sure that the order has been served to the guest.',
        paymentPendingDescription:
          'Please make sure that the order has been served to the guest and payment has been collected.',
      },
      delivery: {
        paymentDoneDescription: 'Please make sure that the order has been delivered to the guest.',
      },
      settleOrderObj: {
        title: 'Settle Order',
        backBtn: 'BACK',
        actionBtn: 'SETTLE',
      },
    },
    readyOrderPopup: {
      selfPickupReadyOrderObj: {
        title: 'Order is Ready',
        description:
          'Please make sure that the order is ready to be picked up. We will notify the guest to collect the order.',
        backBtn: 'BACK',
        actionBtn: 'MARK READY',
      },
      deliveryReadyOrderObj: {
        title: 'Order is ready for Delivery',
        description:
          'Please make sure that the order is ready for delivery. We will notify the guest that their order is out for delivery.',
        backBtn: 'BACK',
        actionBtn: 'START DELIVERY',
      },
    },
  },
  payments: {
    paymentDone: 'Payment Done',
    paymentPending: 'Payment Pending',
    paidToRestaurant: 'Paid to Restaurant',
    paymentFailed: 'Payment Failed',
  },
  tableType: {
    table: 'Table',
  },
  guest: {
    name: 'Guest',
    etdLabel: 'ETD',
  },
  orderHistory: {
    page: 1,
    pageSize: 20,
    title: 'History',
    tableTitle: 'Order History',
    sort: 'orderPunchTime',
    searchPlaceHolderText: 'Search by Guest Name, Phone No., Order ID',
    exportOrderHistoryReport: 'Export Order History Report',
    exportOrderItemReport: 'Export Order Items Report',
    ctaText: 'SEARCH',
    toastMessageTime: 3000,
    paymentType: 'Fudr Paid',
    exportOrderHistoryPopupObj: {
      title: 'Export Order History',
      description:
        'Select the date range to export order history report. Order history report will be mailed to registered email address',
      backBtn: 'CANCEL',
      actionBtn: 'EXPORT',
    },
    exportOrderHistoryToastMessage:
      'You’ll shortly receive Order history on your registered email ID.',
    exportOrderItemToastMessage:
      'You’ll shortly receive Order item history on your registered email ID.',
    updateToastMessage: 'You’ll shortly receive Order history on your registered email ID.',
    exportOrderItemsReportPopupObj: {
      title: 'Export Order Items Report',
      description:
        'Select the date range to export order items report. Order items report will be mailed to registered email address',
      backBtn: 'CANCEL',
      actionBtn: 'EXPORT',
    },
    dateRangeSelectText: 'Date range more than 60 days',
    maxRange: 60,
    datPicker: {
      fromLabel: 'From',
      toLabel: 'To',
    },
  },
  modal: {
    confirmation: {
      rejectOrder: {
        title: 'Reject Order',
        description: 'Are you sure you want to reject this order?',
        backBtn: 'BACK',
        actionBtn: 'REJECT',
      },
      updateMenu: {
        title: 'Update Item',
        description: 'Do you want to change the status of the item?',
        backBtn: 'BACK',
        actionBtn: 'UPDATE',
      },
      enableMenuCategoryToggle: {
        title: 'Update Category',
        description: 'Do you want to change the status of the category?',
        backBtn: 'BACK',
        actionBtn: 'CONFIRM',
      },
      addSubCategoryError: {
        description: "Can't add sub-category in a category containing menu items.",
        actionBtn: 'OK',
      },
      editOrderWarningMessage: {
        description:
          'Guest has applied an offer or extra discount has been given on this order. Orders with offer / extra discount cannot be edited. You can still reject this order instead.',
        actionBtn: 'OK',
      },
    },
    editOrder: {
      title: 'Edit Order',
      backBtn: 'BACK',
      actionBtn: 'UPDATE',
      itemName: 'Item Name',
      quantity: 'Quantity',
      cancelReasonHeaderTitle: 'Reason',
      subTitle: `Reducing quantity of a paid item will initiate a refund to the guest.\nIt may take upto 2-3 business days to reflect in guest's account.`,
      cancelReasonsDropdownPlaceholder: 'Reason for reducing quantity',
      customReasonTextfieldPlaceholder: 'Provide a reason for reducing quantity',
      emptyCustomReasonErrorMessage: 'Please provide a reason for reducing quantity',
    },
    printKOT: {
      title: 'Print KOT',
      backBtn: 'CANCEL',
      actionBtn: 'PRINT',
      description: 'Select item to include in KOT Print',
    },
  },

  card: {
    printOrderKOT: 'Print KOT',
    printSummaryKOT: 'Print Summary',
    rejectOrder: 'Reject Order',
    editOrder: 'Edit Order',
    applyDiscount: 'Apply Discount',
    removeCharges: 'Remove Charges',
    viewSummary: 'View Summary',
    changeTable: 'Change Table',
    transactionId: 'Transaction Id:',
    tableNumber: 'Location',
    item: 'Item',
    items: 'Items',
    cancelledItems: 'Cancelled Items',
    specialInstructionText: 'Special Instruction',
    guestAtRestaurant: 'Guest at Restaurant',
    offeredItems: 'Offered Items',
    paidByCashCard: 'Paid by Cash Card',
    paymentPending: 'Payment Pending',
    paidByFudrPay: 'Fudr Paid',
    extraDiscount: 'Extra Discount',
    posSyncStatus: {
      success: 'Synced with POS',
      pending: 'POS sync in progress',
      failed: 'Order not synced with POS',
      fetching: 'Getting POS data',
      viewErrorsCTAText: 'view errors',
    },
  },
  orderSummaryCard: {
    item: 'Item',
    items: 'Items',
    cancelledItems: 'Cancelled Items (not included in summary)',
    specialInstructionText: 'Special Instruction',
    showCancelledItems: 'Show Cancelled Items',
    hideCancelledItems: 'Hide Cancelled Items',
    guestAtRestaurant: 'Guest at Restaurant',
    orderCancelled: 'Order Cancelled',
    billAmount: {
      offer: 'Offer',
      extraDiscount: 'Extra Discount',
      total: 'Total',
    },
  },
  foodItem: {
    offeredItemsTitle: 'Offered items',
    customizedItem: 'Customized Item',
  },
  sideNavTabs: {
    orders: 'Orders',
    history: 'History',
    menu: 'Menu',
    guests: 'Guests',
    settings: 'Settings',
    offers: 'Offers',
    upsell: 'Upsell',
    cashCard: 'Cash Card',
    account: 'Account',
    integrations: 'INTEGRATIONS',
    pos: 'POS',
  },
  availabeSideNavTabs: [
    'orders',
    'menu',
    'history',
    'guests',
    'offers',
    'settings',
    'upsell',
    'cash-card',
    'account',
    'pos',
  ],
  signup: {
    createAccount: 'Create Account',
    existingAccount: 'Existing Account? ',
    login: 'Login',
    label: {
      name: 'Name',
      outletName: 'Outlet Name',
      phoneNo: 'Phone no.',
      email: 'Email (Verification link will be sent on this address)',
      username: 'Username',
    },
    placeHolder: {
      name: 'Enter your name',
      outletName: 'Enter your outlet name',
      phoneNo: 'Contact no.',
      email: 'Enter your email address',
      username: 'Create a uniquee username',
    },
    signUpBtnCTA: 'CREATE ACCOUNT',
    verifyEmail: {
      title: 'Verify email address',
      description:
        'You’re just one step away from setting up your account. Click on the link sent on your email to verify account.',
      verificationText: 'Verification link sent on,',
    },
    emptyErrorMessage: {
      name: 'Please enter your name',
      outletName: 'Please enter your outlet name',
      phoneNumber: 'Please enter your phone no.',
      email: 'Please enter your email',
      username: 'Please enter username',
      recaptcha: "Please verify that you're not a robot",
    },
    invalidErrorMessage: {
      email: 'Please enter a valid email id',
      phoneNumber: 'Please enter a valid phone no.',
      username: 'This name is already taken',
    },
    genericErrorText: 'Please resolve highlighted issues to proceed',
  },
  body: {
    ordersText: 'Orders',
    refresh: 'REFRESH',
    noOrdersText: 'No Orders to Display',
    readOnlyText: 'Manage orders on your Point of Sale (POS) System',
  },
  menu: {
    title: 'Menu',
    menuItems: 'Menu Items',
    categoriesHeading: 'Categories',
    itemsHeading: 'Items',
    itemDetailsHeading: 'Item Details',
    customizations: 'Customizations',
    noCustomizations: 'No Customizations to Display',
    noMedia: 'No Photos Added',
    singleSelect: 'Single Select',
    multiSelect: 'Multi Select',
    customizationsGroupHeading: 'Group',
    customizationsAppliedOnHeading: 'Applied On',
    viewMoreText: 'view more',
    viewLessText: 'view less',
    addBtnText: 'ADD',
    itemType: {
      category: 'Category',
      menuItem: 'Menu Item',
      customizedGroup: 'Group',
      customizedItem: 'Item',
    },
    itemOps: {
      add: 'ADD',
      edit: 'EDIT',
    },
    emptyMenuItemsPlaceholder: 'No items in this category',
    underlayColumnText: 'Details of the selected item are shown here.',
    switchLinkText: {
      oldMenu: 'Switch back to old look',
      newMenu: 'Try the new look',
    },
    addItemSuccessToast: 'Item added successfully',
    updateItemSuccessToast: 'Item updated successfully',
    reorderCategorySuccessToast: 'Categories reordered successfully',
    reorderItemSuccessToast: 'Items reordered successfully',
    emptyMenuItemsCTAText: 'ADD ITEM',
    addCategorySuccessToast: 'Category added successfully',
    updateCategorySuccessToast: 'Category updated successfully',
    unavailabilityTagPrefixText: 'Not available for',
    categoryDetails: {
      editSubCategoryCTA: 'Edit Sub-category',
      addSubCategoryCTA: 'Add Sub-category',
      editCategoryCTA: 'Edit Category',
      enableCategoryCTA: 'Enable Category',
      enableSubCategoryCTA: 'Enable Sub-category',
      disableCategoryCTA: 'Disable Category',
      disableSubCategoryCTA: 'Disable Sub-category',
      deleteCategoryCTA: 'Delete Category',
      deleteItemCTA: 'Delete Item',
      cancelCTA: 'CANCEL',
      updateCTA: 'UPDATE',
      addCTA: 'ADD',
      addCategoryTitle: 'Add Category',
      editCategoryTitle: 'Edit Category',
      fallBackTitle: 'Category Details',
      categoryNameLabel: 'Category Name',
      descriptionLabel: 'Description',
      subCategoryNameLabel: 'Sub-category Name',
      categoryNamePlaceholder: 'Enter menu category name',
      categoryDescriptionPlaceholder: 'Add small description about the Category',
      mainCategoryLabel: 'This is a main category.',
      subCategoryLabel:
        'This is a sub-category. It will be shown under the following main category.',
      convertToMainCategoryCTA: 'Convert to main category',
      convertToSubCategoryCTA: 'Convert to Sub-category',
      categoryNameMaxLength: 100,
      categoryDescriptionMaxLength: 500,
      parentCategorySelectionLabel: 'Main Category',
      parentCategorySelectionPlaceholder: 'Select a category',
      emptyParentCategoryErrorMessage: 'Please select a category',
      emptyNameErrorMessage: 'Please enter a name for this category',
      basicInfoSectionTitle: 'Basic Information',
      modeAvailabilitySectionTitle: 'Mode Availability',
      timeAvailabilitySectionTitle: 'Time Availability',
      modeAvailabilitySectionDescription:
        'Define the order modes in which this category is available for the guest.',
      timeAvailabilitySectionDescription:
        'Provide the time range in which the category is available for guests. Eg. 11am - 1pm & 5pm - 7pm. By default, it is available all day (restaurant timings)',
      modeServiceLabelsPartialText: 'Orders',
      openingTimeLabel: 'Opening Time',
      closingTimeLabel: 'Closing Time',
      addTimeRangeCTAText: 'ADD TIME RANGE',
      genericError: 'Resolve the highlighted issues to proceed',
      convertToSubCategoryError:
        'A main category cannot be converted to sub-category if it already contains sub-categories.',
      orderModesEmptyResultMessage:
        'Please activate at least one order mode on the Settings page accessible from the sidebar',
    },
    recommendedSectionInstructionText:
      'Select upto {recommendedMenuItemsLimit} popular items from the menu. Suggest to include Items having photos. To add item in this category, Edit the item and select Recommended checkbox.',
    categoryDisabledInfoText:
      'This category is disabled. All the items in this category will not be visible to guest.',
    parentCategoryDisabledInfoText:
      "This category's main category is disabled. All the items in this category will not be visible to guest.",
    deleteCategorySuccessToast: 'Category deleted successfully',
    deleteMenuItemSuccessToast: 'Menu Item deleted successfully',
    deleteCategoryPopupObj: {
      title: 'Delete Category',
      description: `Are you sure you want to delete this category? All items of this category will be deleted. Any upsell items associated with these menu items will be deleted as well.\n
      To change an item’s category, ‘Edit Item’ and change its category. \n
      To move sub category, ‘Edit Sub Category’ and change its main category.`,
      backBtn: 'CANCEL',
      actionBtn: 'DELETE',
    },
    deleteMenuItemPopupObj: {
      title: 'Delete Item',
      description:
        'Are you sure you want to delete this menu item? Any upsell item associated with this menu item will be deleted as well.',
      backBtn: 'CANCEL',
      actionBtn: 'DELETE',
    },
  },
  menuItemDetails: {
    title: 'Item Details',
    editCTA: 'EDIT',
    customizationText: 'Customization',
    mediaTitleText: 'Photos',
    doneCTA: 'DONE',
    singleSelect: 'Single select',
    multiSelect: 'Multi select',
    addItemTitle: 'Add Item',
    editItemTitle: 'Edit Item',
    deleteItem: 'Delete Item',
    addCTA: 'ADD ITEM',
    updateCTA: 'UPDATE ITEM',
    cancelBtn: 'CANCEL',
    backBtn: 'GO BACK',
    taxInclusiveLabel:
      'Price is inclusive of taxes.\n Tax rate is required for billing purpose only.',
    taxExclusiveLabel: 'Price is exclusive of taxes.\n Tax will be added to the final price.',
    sectionTitle: {
      basic: 'Basic Information',
      media: 'Photos',
      customizations: 'Customizations',
    },
    label: {
      itemName: 'Item Name',
      category: 'Category',
      foodType: 'Type',
      price: 'Price (displayed on menu)',
      tax: 'Tax @',
      itemDescription: 'Item Description',
      customizationPrice: 'Price',
      recommended: 'Recommended Item',
      itemPrice: 'Item Price',
    },
    placeholder: {
      itemName: 'Enter dish name',
      category: 'Select a category',
      description:
        'Add small description about the dish. Eg. Cottage Cheese Marinated in Tandoori Masala With Classical Curd with touch of Steam rice and Brown Gravy',
    },
    itemNameMaxLength: '100',
    itemDescriptionMaxLength: '500',
    errorMessage: {
      emptyName: 'Please enter a name for this item',
      invalidPrice: 'Please enter a valid price',
    },
    addCustomizationTitle: 'Add Customization',
    editCustomizationTitle: 'Edit Customization',
    customizationPopupSubTitle:
      'Prices shown are the base prices. You may change it after adding the customisation.',
    addCustomizationCTA: 'ADD CUSTOMIZATION',
    editCustomizationCTA: 'UPDATE CUSTOMIZATION',
    manageCustomizationCTA: 'MANAGE',
    customizationSectionDescriptionPartOne:
      'Price mentioned here will be added to the item price. Item Price + Customization Price = Final Price. By default, all customization prices are inclsive of tax of',
    recommendedInfoMessage: 'Show item in the Recommended Section',
    recommendedExceedError: `Max limit of {recommendedMenuItemsLimit} reached. Remove items from Recommended category to add this item`,
    itemDisabledText: 'Item will be not be shown to guests',
    genericError: 'Resolve the highlighted issues to proceed',
    imagesSupportCriteriaLabel: 'Supported file format .jpg, .png. Max file size 5MB',
    imageMaxSizeExceedWarning: 'Files larger than 5MB not supported',
    addMediaCTA: 'ADD PHOTO',
    addMediaTitle: 'Add Photo',
    editMediaTitle: 'Edit Photo',
    editMediaCTA: 'UPDATE PHOTO',
    mediaOptionsCTA: {
      edit: 'Edit',
      delete: 'Remove',
    },
  },
  unattendedStatus: ['UNATTENDED'],
  attendedStatus: ['CONFIRMED', 'READY'],
  headers: {
    totalOrdersText: 'Total Orders',
    totalTransactionText: 'Total Transaction',
    lastUpdatedText: 'Last Updated',
  },
  guests: {
    title: 'Guests',
    tableTitle: 'Guest List',
    searchPlaceHolderText: 'Search by Guest Name, Phone no., Email',
    ctaText: 'SEARCH',
    page: 1,
    defaultPageSize: 20,
    defaultSortType: 'name',
    guestOrderSortType: 'orderPunchTime',
    exportGuestList: 'Export Guest List',
    toastMessageTime: 3000,
    guestDetails: {
      title: ' Guest Details',
      name: 'Name',
      contactNumber: 'Mobile No.',
      email: 'Email',
    },
    orderStatus: {
      totalOrders: 'Total Orders',
      totalAmount: 'Total Amount',
      pointsEarned: 'Points Earned',
      pointsRedeemed: 'Points Redeemed',
      pointsBalance: 'Points Balance',
    },
    exportGuestListPopupObj: {
      title: 'Export Guest List',
      description: 'Guest list will be mailed on your official email id',
      backBtn: 'CANCEL',
      actionBtn: 'EXPORT',
    },
    updateToastMessage: 'You’ll shortly receive Guest list on your registered email ID.',
  },
  settings: {
    title: 'Settings',
    settingsColumnTitle: 'Settings',
    pauseOrdersCTAText: 'PAUSE ORDERS',
    resumeOrdersCTAText: 'RESUME ORDERS',
    ordersPausedText: 'Orders Paused',
    restaurantInactiveText: 'Restaurant is currently not accepting FUDR orders. Resume orders now.',
    resumeServicesCTAText: 'RESUME SERVICES',
    additionalServicesInactiveText:
      'Digital waiter services have been paused. Resume services now.',
    toastMessageTime: 3000,
    defaultUpdateToastMessage: 'Settings updated',
    ordersStatusUpdateToastMessage: `Orders' status updated`,
    switchLinkText: {
      oldView: 'Switch back to old look',
      newView: 'Try the new look',
    },
    pauseOrdersObj: {
      title: 'Pause Orders',
      description:
        'Are you sure you want to Pause Orders? Guests would not be able to place order.',
      backBtn: 'BACK',
      actionBtn: 'PAUSE ORDERS',
    },
    resumeOrdersObj: {
      title: 'Resume Orders',
      description: 'Are you sure you want to resume orders? Guests would be able to place order.',
      backBtn: 'BACK',
      actionBtn: 'RESUME ORDERS',
    },
    inclusiveExclusiveTaxPopup: {
      title: 'Edit Price',
      dropDownLabel: 'Pricing',
      inclusiveExclusiveOption: [
        { key: 'IN-TAX', text: 'Prices shown on menu are inclusive of taxes', value: 'IN-TAX' },
        { key: 'EX-TAX', text: 'Prices shown on menu are exclusive of taxes', value: 'EX-TAX' },
      ],
      taxTypeUpdateToastMessage: 'Menu pricing tax updated',
      backBtn: 'BACK',
      actionBtn: 'UPDATE',
    },
    orderModesAndServicesPanelTitle: 'Order Modes & Digital Waiter Services',
    orderModesAndServicesPanelSubTitle:
      'Dine-in, Self Pickup, Delivery | Call waiter, Request bill…',
    orderModesSettings: {
      orderModesTitle: 'Order Modes',
      orderModesSubTitle: 'Order Modes supported by the outlet',
      orderModePauseCTAText: 'PAUSE',
      orderModeResumeCTAText: 'RESUME',
      editTitle: 'Edit Title',
      popup: {
        title: 'Edit Order Modes',
        description: 'Select the order modes supported by the restaurant',
        backBtn: 'BACK',
        actionBtn: 'UPDATE',
        orderModeDescriptions: {
          DI: 'Orders will be placed by the guests visiting the restaurant',
          RO: 'Orders will be placed from guest’s room',
          SP: 'Orders will be collected by the guests from the restaurant',
          DT: 'Guests can order from their car by providing their Car Number',
          DL: 'Orders will be delivered by the restaurant at guest’s address',
        },
      },
      updateToastMessage: 'Order modes updated',
      orderModesStatusPopupContent: {
        SP: {
          pauseObj: {
            title: 'Pause Self Pick-up',
            description:
              'Are you sure you want to Pause Self Pick-up Orders? Guests will not be able to place Self Pick-up Orders.',
            backBtn: 'BACK',
            actionBtn: 'PAUSE SELF PICK-UP',
          },
          resumeObj: {
            title: 'Resume Self Pick-up',
            description: 'Are you sure you want to Resume Self Pick-up?',
            backBtn: 'BACK',
            actionBtn: 'RESUME SELF PICK-UP',
          },
          pausedText: 'Self Pick-up Orders Paused',
        },
        DI: {
          pauseObj: {
            title: 'Pause Dine-In',
            description:
              'Are you sure you want to Pause Dine-In Orders? Guests will not be able to place Dine-in Orders.',
            backBtn: 'BACK',
            actionBtn: 'PAUSE DINE-IN',
          },
          resumeObj: {
            title: 'Resume Dine-In',
            description: 'Are you sure you want to Resume Dine-In?',
            backBtn: 'BACK',
            actionBtn: 'RESUME DINE-IN',
          },
          pausedText: 'Dine-In Orders Paused',
        },
        DT: {
          pauseObj: {
            title: 'Pause Drive Through',
            description:
              'Are you sure you want to Pause Drive Through Orders? Guests will not be able to place Drive Through Orders.',
            backBtn: 'BACK',
            actionBtn: 'PAUSE DRIVE THROUGH',
          },
          resumeObj: {
            title: 'Resume Drive Through',
            description: 'Are you sure you want to Resume Drive Through?',
            backBtn: 'BACK',
            actionBtn: 'RESUME DRIVE THROUGH',
          },
          pausedText: 'Drive Through Orders Paused',
        },
        RO: {
          pauseObj: {
            title: 'Pause Room',
            description:
              'Are you sure you want to Pause Room Orders? Guests will not be able to place Room Orders.',
            backBtn: 'BACK',
            actionBtn: 'PAUSE ROOM',
          },
          resumeObj: {
            title: 'Resume Room',
            description: 'Are you sure you want to Resume Room?',
            backBtn: 'BACK',
            actionBtn: 'RESUME ROOM',
          },
          pausedText: 'Room Orders Paused',
        },
        pauseText: 'PAUSE',
        resumeText: 'RESUME',
      },
    },
    additionalServicesSettings: {
      additionalServicesTitle: 'Digital Waiter Services',
      additionalServicesSubTitle: 'Digital waiter services provided by the outlet',
      servicesPausedText: 'Services Paused',
      pauseServicesCTAText: 'PAUSE',
      resumeServicesCTAText: 'RESUME',
      additionalServicesStatusUpdateToastMessage: `Digital waiter services' status updated`,
      additionalServicesUpdateToastMessage: 'Digital waiter services updated',
      addServicesCTAText: 'ADD SERVICES',
      resumeServicesObj: {
        title: 'Resume Services',
        description:
          'Are you sure you want to resume digital waiter services? Guests would be able to request for services.',
        backBtn: 'BACK',
        actionBtn: 'RESUME SERVICES',
      },
      pauseServicesObj: {
        title: 'Pause Services',
        description:
          'Are you sure you want to pause digital waiter services? Guests would not be able to use services.',
        backBtn: 'BACK',
        actionBtn: 'PAUSE SERVICES',
      },
      popup: {
        title: 'Edit Services',
        description: 'Select the services',
        backBtn: 'BACK',
        actionBtn: 'UPDATE',
      },
    },
    deliverySettings: {
      pauseDeliveryObj: {
        title: 'Pause Home Delivery',
        description:
          'Are you sure you want to Pause Home Delivery? Guests will not be able to get their orders home delivered.',
        backBtn: 'BACK',
        actionBtn: 'PAUSE HOME DELIVERY',
      },
      resumeDeliveryObj: {
        title: 'Resume Home Delivery',
        description:
          'Are you sure you want to Resume Home Delivery? Guests will be able to choose to get their orders home delivered.',
        backBtn: 'BACK',
        actionBtn: 'RESUME HOME DELIVERY',
      },
      pauseDeliveryText: 'PAUSE',
      resumeDeliveryText: 'RESUME',
      deliveryPausedText: 'Home Delivery Orders Paused',
      deliveryChargesPopup: {
        title: 'Edit Delivery Charges',
        backBtn: 'BACK',
        actionBtn: 'UPDATE',
        deliveryCharges: [
          { key: 'FR', label: 'Free' },
          {
            key: 'FX',
            label: 'Fixed',
            description: 'Guests will be charged a fixed amount for home delivery',
          },
          {
            key: 'OV',
            label: 'Order Value',
            description: `Guests will be charged as per the Order amount. eg. For order amount ₹ 0 - 300, delivery charge ₹30. Orders above ₹300, delivery free.`,
          },
          {
            key: 'OD',
            label: 'Delivery Distance',
            description: `Guests will be charged as per the delivery distance.
            Eg. For 0-1km delivery free; distance above 5km delivery charge ₹30`,
          },
        ],
        deliveryChargesLabel: 'Delivery Charges',
        deliveryChargeLabel: 'Delivery Charge',
        orderRangeLabel: 'Order Range',
        deliveryDistanceLabel: 'Delivery distance(km)',
        addRangeText: 'Add Range',
        rangeErrorText: 'Enter Valid Range',
        chargeErrorText: 'Enter Valid Charge',
        genericErrorText: 'Resolve the highlighted issues to proceed',
      },
      deliveryChargeTypeTitle: {
        OD: 'Distance',
        OV: 'Order value',
        FX: 'Fixed',
        FR: 'Free',
      },
      orderAmountLabel: 'Order Amount',
      deliveryChargeLabel: 'Delivery Charge',
      zeroChargeText: 'Free',
      deliveryChargesUpdateToastMessage: 'Delivery charges updated',
    },
    deliveryLocationsPanelTitle: 'Delivery Locations',
    deliveryLocationsPanelSubTitle: 'Select delivery locations',
    deliveryLocationsSettings: {
      allDeliveryLocalitiesPartialLabel: 'Delivering across',
      selectLocalitiesBtnText: 'SELECT SPECIFIC LOCALITIES',
      popup: {
        title: 'Edit Delivery Locations',
        deliveryLocalityLabel: 'Delivery Locality',
        deliveryLocalitySubLabel:
          'Select the localities where you can comfortably manage deliveries',
        localitySearchPlaceholder: 'Search for locality',
        allLocalitiesLabel: 'All Localities',
        backBtn: 'BACK',
        actionBtn: 'UPDATE',
      },
      updateToastMessage: 'Delivery locations updated',
    },
    qrCodesPanelTitle: 'QR Codes',
    qrCodesPanelSubTitle: 'QR codes, Payment type',
    qrCodesSettings: {
      addQRCode: 'Add QR Code',
      addSection: 'Add Section',
      downloadQrcodes: 'Download all QR Codes',
      tableTitle: 'Title',
      tableOrderMode: 'Order Mode',
      tablePaymentType: 'Payment Type',
      prePaid: 'Pre-paid',
      postPaid: 'Post-paid',
      addQRCodePopup: {
        title: 'Feature Coming Soon',
        description: 'To add a QR code, please drop a mail to support@fudr.in',
        actionBtn: 'DONE',
      },
    },
    pricingAndChargesPanelTitle: 'Pricing & Additional Charges',
    pricingAndChargesPanelSubTitle: 'Tax on menu items, Service charge, Packaging charge…',
    pricingSettings: {
      pricingTitle: 'Pricing',
      menuPricingTitle: 'Menu Pricing',
      pricingTaxInclusiveText: 'Prices shown on menu are inclusive of taxes',
      pricingTaxExclusiveText: 'Prices shown on menu are exclusive of taxes',
    },
    additionalChargesSettings: {
      additionalChargesTitle: 'Additional Charge',
      deliveryChargesTitle: 'Delivery Charge',
      applicableOnLabel: 'Applicable On',
      orderAmountLabel: 'Order Amount',
      deliveryChargeLabel: 'Delivery Charge',
      addDeliveryChargeCTAText: 'Add Delivery Charge',
      additionalChargeEditText: 'Edit',
      additionalChargeRemoveText: 'Remove',
      popup: {
        titlePrefix: 'Edit',
        addedTaxLabel: 'Added Tax',
        applicableOnLabel: 'Charge Applicable on',
        backBtn: 'BACK',
        actionBtn: 'UPDATE',
        digitAfterDecimal: 2,
        errorMessage: {
          genericErrorText: 'Please resolve the highlighted issues',
          noOrderModesSelected:
            'Please select at least one order mode where the charge is applicable',
          invalidPrice: 'Please enter a valid price',
          invalidRupee: 'Please enter a value greater than 0',
          invalidPercentage: 'Please enter a value between 1-100',
        },
        dropDownPlaceholder: 'Select...',
      },
      updateToastMessage: 'Additional charge updated',
      addToastMessage: 'Additional charge added',
      deleteToastMessage: 'Additional charge removed',
      deleteAdditionalChargePopup: {
        title: 'Remove Additional Charge',
        description: 'Are you sure you want to remove this additional charge?',
        actionBtn: 'REMOVE',
        backBtn: 'CANCEL',
      },
    },
    timingsPanelTitle: 'Timings',
    timingsPanelSubTitle: 'Restaurant timings, Mode specific timings',
    timings: {
      timingsText: 'Timings',
      restaurantTimingsLabel: 'Restaurant Timings',
      sameAsRestaurantTimingsText: 'Same as Restaurant timings',
      timingOptions: {
        restaurant: 'restaurant',
        custom: 'custom',
      },
      showWeekDaysLabel: 'Show week days',
      opensAt: 'Opens at',
      closesAt: 'Closes at',
      dayStatusClosed: 'Closed',
      defaultDay: 'DEFAULT',
      popup: {
        editPopupTitlePrefixText: 'Edit',
        backBtn: 'BACK',
        actionBtn: 'UPDATE',
        timingOptions: {
          restaurant: 'Same as Restaurant Timings',
          custom: 'Custom Timings',
        },
        dayStatus: {
          open: 'Open',
          closed: 'Closed',
        },
      },
      restaurantTimingsUpdateToastMessage: 'Restaurant timings updated',
      dineInTimingsUpdateToastMessage: 'Dine-in timings updated',
      selfPickupTimingsUpdateToastMessage: 'Self Pickup timings updated',
      deliveryTimingsUpdateToastMessage: 'Delivery timings updated',
      driveThroughTimingsUpdateToastMessage: 'Drive Through timings updated',
      roomTimingsUpdateToastMessage: 'In-room Dining timings updated',
    },
    notificationsAndCommunicationPanelTitle: 'Notifications & Communication',
    notificationsAndCommunicationPanelSubTitle: 'Sound, Email, Contact no.',
    notificationsSettings: {
      orderNotificationsTitle: 'Order Notification',
      soundTitle: 'Sound',
      orderNotificationSettings: {
        popup: {
          title: 'Edit Order Notification',
          backBtn: 'BACK',
          actionBtn: 'UPDATE',
          soundSectionTitle: 'Sound',
          soundSectionDescription: 'Selected tone will be played for every new order',
          playbackModeSupportingText: {
            S: '(the tone will be played once)',
            C: '(the tone will be played until order is attended)',
          },
        },
        updateToastMessage: 'Order notification settings updated',
      },
    },
    communicationSettings: {
      communicationTitle: 'Communication',
      guestCommunicationTitle: 'Guest Communication',
      noContactProvided: 'No Contact no. provided',
      guestCommunicationSettings: {
        popup: {
          title: 'Edit Guest Communication',
          backBtn: 'BACK',
          actionBtn: 'UPDATE',
          subTitle: 'Contact Number',
          description:
            'Your guests will be able to contact restaurant on the selected Phone numbers',
          addNewContactCTAText: 'Add New Contact',
          deleteContactConfirmationText: 'Delete contact?',
        },
        duplicateEntry: 'Contact already added',
        invalidContact: 'Please enter a valid contact',
        genericErrorText: 'Please resolve the highlighted issues',
        updateToastMessage: 'Guest communication settings updated',
      },
      notificationEmailSettings: {
        popup: {
          titlePrefix: 'Edit',
          subTitle: {
            newOrder: 'New orders will be notified on the provided email address',
            orderSummary: 'Order summary will be sent to the provided email address',
            offer: 'Offer updates / New offer will be notified on the provided email address',
            guestList: 'Guest list will be sent to the provided email address',
            cancelOrder: 'Cancelled orders will be notified on the provided email address',
            customerSupport: 'Customer support queries will be sent to the provided email address',
          },
          backBtn: 'BACK',
          actionBtn: 'UPDATE',
          errorMessage: {
            atLeastOneEntry: 'Please provide at least one email address',
            invalidEmailAddress: 'Please enter a valid email address',
            duplicateEntry: 'Email already added',
            genericErrorText: 'Please resolve the highlighted issues',
          },
          addNewEmailCTAText: 'Add New Email',
          deleteEmailConfirmationText: 'Delete Email?',
          emptyEmailListText: 'No email id provided',
          defaultEmailInfoText: 'Mail will be sent to default email id',
        },
        updateToastMessage: {
          newOrder: 'New order notification settings updated',
          orderSummary: 'Order summary notification settings updated',
          offer: 'Offer notification settings updated',
          guestList: 'Guest list notification settings updated',
          cancelOrder: 'Cancelled order notification settings updated',
          customerSupport: 'Customer support notification settings updated',
        },
        emptyResultPlaceholder: 'No email address provided',
      },
    },
    widgetsAndLinksPanelTitle: 'Digital Store',
    widgetsAndLinksPanelSubTitle: 'Your App, Digital Store Links',
    outletAppSettings: {
      outletAppTitle: 'Your Outlet App',
      preCreationOutletAppSubTitle: 'Let your guests download your outlet’s app',
      postCreationOutletAppSubTitle: 'Your app is ready for your guests to download',
      createAppCTAText: 'CREATE APP',
      appLinkText: 'App Link',
      copyAppDownloadLinkText: 'COPY LINK',
      linkCopiedToastMessage: 'Link copied',
      popup: {
        appNameSectionTitle: 'App Name',
        appNameSectionSubTitle: 'Please define the name of your App',
        appNameInputPlaceholder: 'App Name',
        appNameRecommendationText: 'Recommended to use App Name shorter than 12 characters',
        appIconSectionTitle: 'App Icon',
        appIconSectionInstructions: `Please use Square Image for best results.\nRecommended size - 512px X 512px  |  Supported formats - JPG, PNG  |  Maximum file size - 5MB`,
        uploadAppIconCTAText: 'UPLOAD APP ICON',
        changeAppIconCTAText: 'CHANGE APP ICON',
        appIconInvalidDimensionsErrorText:
          'App icon dimensions cannot be less than 512 px X 512 px',
        emptyImageError: 'Please provide an app icon',
        emptyAppNameErrorText: 'App Name cannot be left blank',
        genericErrorMessage: 'Resolve the highlighted issues to proceed',
        ADD: {
          title: 'Your Outlet App',
          actionBtn: 'CREATE',
          backBtn: 'CANCEL',
          toast: 'Outlet app created',
        },
        EDIT: {
          title: 'Edit App',
          actionBtn: 'UPDATE',
          backBtn: 'CANCEL',
          toast: 'Outlet app settings updated',
        },
      },
      appReadyText: 'Your app is ready for your guests to download',
      copyLinkCTAText: 'Copy link',
      removeAppCTAText: 'REMOVE APP',
    },
    menuWidgetSettings: {
      menuWidgetTitle: 'Fudr Menu Widget',
    },
    menuLinkSettings: {
      menuLinkTitle: 'Fudr Menu Link',
      menuLinkSubTitle:
        'Add Fudr menu link to your social media page to convert page visits into orders.',
      menuLinkGuideLinkText: 'How it works',
      customLinkLabel: 'Custom Link',
      createCustomLinkBtn: 'CREATE CUSTOM LINK',
      copyLink: 'COPY LINK',
      customLinkSubDomain: 'c',
      customLinksReqObjCountPerPage: 50,
      linkCopiedToastMessage: 'Link copied',
      popup: {
        title: 'Edit Custom Link',
        brandLinkPlaceholder: 'unique brand title',
        backBtn: 'BACK',
        actionBtn: 'UPDATE',
      },
      errorMessage: {
        emptyCustomCode: 'Please enter a title',
        notAlphaNumericWithSpace: 'Please enter only alpha-numeric characters with no spaces',
        notUniqueCustomCode: 'Title already in use. Please enter a unique title.',
        genericErrorText: 'Resolve the highlighted issues to proceed',
      },
      updateToastMessage: 'Menu link updated',
    },
    usersPanelTitle: 'Users',
    usersPanelSubTitle: 'Roles & Permissions',
    documentsPanelTitle: 'Documents',
    documentsPanelSubTitle: 'FSSAI, GST certificate, Bank details',
    commonCopyLink: {
      copyLink: 'COPY LINK',
      toastMessage: 'Link copied',
      toastMessageTime: 3000,
    },
  },
  account: { title: 'Account' },
  assistance: {
    serviceRequestedText: 'Service Requested',
    location: 'Table no.',
    update: 'Update',
    attendCTA: 'ATTEND',
    newServiceReqText: 'New Service Requested',
    emptyAssistanceMessage: 'You are up to date',
  },
  orderSummary: {
    orderSummaryObj: {
      title: 'Order Summary',
      actionBtn: 'CLOSE',
    },
    totalOrdersText: 'Total Orders',
    totalTransactionText: 'Total Transaction',
    paidOnlineText: 'Fudr paid:',
    paidAtRestaurantText: 'Paid to Restaurant:',
    dateRangeSelectText: 'Date range more than 60 days',
    genericErrorMessage: 'Resolve the highlighted issues to proceed.',
    invalidDateErrorMessage: 'Please enter a valid date',
    maxRange: 60,
    datPicker: {
      fromLabel: 'From',
      toLabel: 'To',
    },
    applyCTA: 'APPLY',
  },
  offers: {
    title: 'Offers',
    dropdownOptions: {
      viewDetails: 'View Details',
      editOffer: 'Edit Offer',
      endAndArchiveOffer: 'End & Archive Offer',
      pauseOffer: 'Pause Offer',
      resumeOffer: 'Resume Offer',
    },
    viewMoreOffers: 'View More Offers',
    createNewOffer: 'CREATE NEW OFFER',
    viewArchivedOffers: 'View Expired & Archieved Offers',
    noActivatedOffer: 'No Activated Offer',
    infoTitle: 'Information',
    basicInfo: 'Basic Info',
    applicableOn: 'Applicable On',
    durationTitle: 'Duration',
    durationDescription: 'Please select duration of the offer',
    advanceOptions: {
      viewAdvanceOptions: 'View Advanced Options',
      hideAdvanceOptions: 'Hide Advance Options',
      durationDescription: 'to select week days or limited hours',
      discountDescription: 'to add minimum bill value or maximum discount',
    },
    newOffer: 'New Offer',
    editOffer: 'Edit Offer',
    offerDetails: 'Offer Details',
    offerDetailsDescription:
      'Please select the amount of discount (percent / rupees) to be offered.',
    cloneOffer: 'Clone Offer',
    buy: 'Buy',
    get: 'Get',
    free: 'Free',
    and: 'and',
    DD: 'Discount on Bill',
    IO: 'BOGO',
    QD: 'Discount on next items',
    item: 'item',
    offOnMinBillOf: 'off on minimum bill of',
    offOnTheBill: 'off on the bill',
    offOnPurchaseOff: 'off on purchase of',
    maxDiscountUpto: 'Maximum discount upto',
    applicableOnlyOn: 'Applicable only on',
    offOnThe: 'off on the',
    offerValidFrom: 'Offer valid from',
    offerPreview: 'Offer Preview',
    addItem: 'Add Item',
    CTABtn: {
      cancelBtn: 'CANCEL',
      createBtn: 'CREATE',
      editCTA: 'EDIT',
      updateBtn: 'UPDATE',
      doneCTA: 'DONE',
      cloneBtn: 'CLONE',
    },
    label: {
      offerCode: 'Code',
      offerType: 'Type',
      offerTitle: 'Title',
      offerDescription: 'Description',
      offerAvailableOn: 'Offer available on',
      offerAvailableFor: 'Offer available for',
      discount: 'Discount',
      menuItemsText: 'Menu Items',
      menuItemsDescription:
        'Please select the menu items / categories on which the offer will be applicable',
      orderModes: 'Order Modes',
      orderModesDescription: 'Please select the order modes on which the offer will be applicable',
      minBillAmount: 'Min. Bill Amount',
      minBillDescription: 'Define the minimum cart value guest need to have to avail this offer',
      maxDiscountAmount: 'Max. Discount Amount',
      maxDiscountDescription: 'Set limit to discount by defining the maximum amount',
      startDate: 'Start Date',
      endDate: 'End Date',
      startTime: 'Start Time',
      endTime: 'End Time',
      allWeekdays: 'All Weekdays',
      selectedWeekdays: 'Selected Weekdays',
      allMenuItems: 'All Menu Items',
      selectedCategories: {
        title: 'Selected Categories',
        SC: 'Limit offer to same category',
        AI: 'Extend offer to all selected categories',
      },
      selectedItems: {
        title: 'Selected Items',
        SI: 'Limit offer to same item',
        AI: 'Extend offer to all selected items',
      },
      date: 'Date',
      timing: 'Timing',
      days: 'Days',
      menuItems: 'Please add items on which the offer will be applicable',
      categoryItems: 'Please select categories on which the offer will be applicable',
      ordersText: 'Orders',
      guests: 'Guests',
      guestsDescription: 'Select the guests who can avail this offer',
      allGuests: 'All Guests',
      all: 'all',
      byRule: 'By Rule',
      fromList: 'From List',
      offerCanBeAvailedBy: 'Offer can be availed by',
      followingGuests: 'following guests',
      showOnlySelected: 'Show only selected',
      guestWhoseOrdersAre: 'Guests whose orders are',
      guestWhoHaveNotVisitedOutlet: 'Guests who’ve not visited outlet in last',
      offerAvailableForAllMenuItems: 'Offer availabe for all menu items',
      viewGuestsList: 'View list',
    },
    tootlTip: {
      selectedCategory: {
        MC: 'Offer will be applicable on all items of the selected categories',
        SC: 'Offer will be applicable on items of the same category',
        AI: 'Offer will be applicable on items of different categories',
      },
      selectedItems: {
        MI: 'Offer will be applicable on selected items',
        SI: 'Offer will be applicable on the same item',
        AI: 'Offer will be applicable on different items',
      },
    },
    toastMessage: {
      toastMessageTime: 3000,
      updateOfferToastMessage: 'Offer updated successfully',
      createOfferToastMessage: 'Offer created successfully',
      archiveOfferToastMessage: 'Offer archived successfully',
      deleteOfferToastMessage: 'Offer deleted successfully',
    },
    placeHolder: {
      offerTitle: 'Enter title of the offer',
      offerDescription: 'Small description about the offer',
      selectedItems: 'Please select the item',
      searchPlaceHolderText: 'Search by Name',
      orderCountOperatorDropdownPlaceholder: 'Select...',
    },
    formValidations: {
      offerCodeMaxLength: '15',
      offerTitleMaxLength: '108',
      offerDescriptionMaxLength: '512',
    },
    itemQuantity: 4,
    errorMessage: {
      alreadyUsedCode: 'Offer code already in use. Use unique offer code.',
      error: 'Resolve the highlighted issues to proceed.',
      minOfferServiceMode: 'Please select minimum one service mode to create offer',
      valueGreaterThanEqualToOne: 'Value must be greater than or equal to 1',
      valueBetweenOneAndHundred: 'Value must be between 1-100',
      valueNonEmptyAndNegative: 'Value cannot be empty or negative',
      offerGuestRule: 'Please select at least one option to proceed.',
      offerGuests: 'Please select at least one guest to proceed.',
      offerCodeMinLength:
        'Offer code must have minimum 3 characters and no special characters (i.e. @#$%^)',
    },
  },
  archivedOffers: {
    title: 'Expired & Archieved Offers',
    dropdownOptions: {
      cloneOffer: 'Clone Offer',
      deleteOffer: 'Delete Offer',
    },
    noArchivedOffers: 'No archived offers',
    viewMoreArchivedOffers: 'View More Archived Offers',
  },
  updateOfferStatus: {
    pauseOffer: {
      title: 'Pause Offer',
      description1: 'Do you want to pause the offer?',
      description2: 'Guests will not be able to avail this offer.',
      reasonText: 'Please provide a reason for pausing the offer.',
      reason: 'Item out of stock',
      cancelBtn: 'CANCEL',
      CTABtn: 'PAUSE',
      otherReason: 'Other reason',
    },
    endArchiveOffer: {
      title: 'End & Archive Offer',
      description1: 'Do you want to end & archive the offer?',
      description2: 'You’ll not be able to offer the discount to guests.',
      reasonText: 'Please provide a reason for ending the offer.',
      reason: 'Offer is not required now',
      cancelBtn: 'CANCEL',
      CTABtn: 'END & ARCHIVE',
    },
    resumeOffer: {
      title: 'Resume Offer',
      description1: 'Do you want to resume this offer?',
      description2: 'Guests will be able to avail this offer.',
      cancelBtn: 'CANCEL',
      CTABtn: 'RESUME',
    },
    deleteOffer: {
      title: 'Delete Offer',
      description:
        'Do you want to delete this offer? You’ll lose access to this offer permanantly.',
      cancelBtn: 'CANCEL',
      CTABtn: 'DELETE',
    },
  },
  preparationTimePopup: {
    min: 'min',
    enterTime: 'Enter Time',
    maxLength: 2,
    preparationTimePopupObj: {
      title: 'Preparation Time',
      etaLabel: 'Guest Estimated Time of Arrival (ETA)',
      etdLabel: 'Estimated Time of Delivery (ETD)',
      description: 'Please provide preparation time of the order',
      backBtn: 'BACK',
      actionBtn: 'ATTEND',
    },
  },
  deliveryTimePopup: {
    min: 'min',
    enterTime: 'Enter Time',
    maxLength: 3,
    deliveryTimePopupObj: {
      title: 'Delivery Time',
      description: 'Please provide estimated delivery time of the order',
      backBtn: 'BACK',
      actionBtn: 'ATTEND',
    },
  },
  regex: {
    preparationTimeValidator: '^([0-9]{1,2})',
    menuItemPriceValidator: '^((?!(0))[0-9]{0,5})$',
  },
  notificationsCTABanner: {
    message: 'Notifications and sound have been denied. Enable them to get updates for new orders.',
    ctaButtonText: 'ENABLE NOTIFICATIONS & SOUND',
  },
  browserPermissionsGuide: {
    instructions: {
      one: 'Click on the Lock Icon 🔒 beside the web address and click on Site settings.',
      two: 'In the list change the value of Sound and Notifications to ‘Allow’.',
    },
    browserPermsPopupObj: {
      title: 'Enable Notifications & Sound',
      actionBtn: 'DONE',
    },
  },
  changeTable: {
    title: 'Change Table',
    CTABtn: {
      cancelBtn: 'CANCEL',
      changeBtn: 'CHANGE',
    },
    currentTable: 'Current Table',
    runningOrders: 'Running Orders',
    noTableAvailable: 'No table available',
  },
  filter: {
    orderFilter: {
      title: 'Filter',
      CTABtn: {
        cancelBtn: 'CANCEL',
        applyBtn: 'APPLY',
      },
      clearAll: 'CLEAR ALL',
      orders: 'Orders',
      tables: 'Tables',
      guest: 'Guest',
      car: 'Car',
      others: 'Others',
      noOrdersAvailable: 'No orders to filter',
    },
    appliedFilteropupObj: {
      title: 'Filters appplied',
      description:
        'Filters are applied in ‘To Settle / Deliver’ section. Some order cards might not be visible',
      backBtn: "DON'T REMOVE FILTERS",
      actionBtn: 'REMOVE FILTERS',
    },
  },
  orderConsolidateSummary: {
    title: 'Summary',
    selectedSummaryTitle: 'Summary (selected orders)',
    offeredItemsText: 'Offered Items',
    CTABtn: {
      doneBtn: 'DONE',
      printSummaryBtn: 'PRINT SUMMARY',
    },
    orderCharges: {
      offers: 'Offers',
      additionalCharges: 'Additional Charges',
      noOffersApplied: 'No offer applied',
      extraDiscount: 'Extra Discount',
      deliveryCharges: 'Delivery Charges',
      deliveryDiscount: 'Delivery Discount',
      tax: 'Tax',
    },
    billTotal: {
      total: 'Total',
      paid: 'Paid',
      pendingAmount: 'Pending Amount',
    },
  },
  comingSoon: 'Coming Soon',
  globalErrorInfo: {
    UNKNOWN_SERVER_ERR: {
      message: 'Something went wrong',
    },
    globalErrorTitle: 'Error',
    internetDisconnectivityErrorMessage: 'No internet. Please check your connectivity.',
    imageLoadError: 'Could not load the image',
    imageMaxSizeExceedWarning: 'Image files larger than 5MB not supported',
    audioLoadingError: 'Unable to load audio',
    formLevelError: 'Please resolve the highlighted issues',
  },
  overlayErrorComponent: {
    viewDetails: 'View Details',
    hideDetails: 'Hide Details',
  },
  KOT: {
    specialInstructionText: 'Special Instructions ',
    itemText: 'Item',
    itemsText: 'Item(s)',
    itemQuantity: 'Qty',
    deliveryTime: 'Delivery Time',
    offeredItems: 'Offered Items',
    summaryKOT: {
      itemPriceText: 'Price (₹)',
      offer: 'Offers',
      extraDiscount: 'Extra Discount',
      serviceCharge: 'Service Charges',
      totalCharge: 'Total',
      paymentDone: 'Payment Done',
      paymentPending: 'Payment Pending',
      additionalCharge: 'Additional Charges',
      paidText: 'Paid',
      prepaidText: 'Fudr Paid',
      postpaidText: 'Paid to Restaurant',
      pendingAmountText: 'Pending Amount',
    },
    basicKOT: {
      fudrBrandingText: 'Powered by Fudr',
    },
  },
  editableChecklist: {
    deleteDenyCTAText: 'No',
    deleteConfirmCTAText: 'Delete',
    deleteItemConfirmationMessage: 'Delete Item?',
    addCTAText: 'Add New Item',
  },
  tooltips: {
    moreOption: 'More Options',
    printKot: 'Print KOT',
    orderId: 'Order Id',
    orderSummary: 'Order Summary',
    filter: 'Filters',
    dragAndDrop: 'Drag & Drop item to change its order',
    orderDetails: 'View Order Details',
    guestDetails: 'View Guest Details',
    cardDetails: 'View Card Details',
    offerStatus: 'Offer Status',
    playSound: 'Play Sound',
    edit: 'Edit',
    delete: 'Delete',
    notifications: 'Notifications',
    logout: 'Logout',
    menuCustomizationSingleSelect: 'Guest can select only one option',
    menuCustomizationMultiSelect: 'Guest can select multiple options',
    toggleItem: 'Enable/Disable item',
    back: 'Back',
    recommended: 'Recommended item',
    toggleOpenStatus: 'Mark open/closed',
    unsavedChanges: 'You’ll lose any unsaved changes',
    refundSource: 'Customer’s source account from which the payment was done',
    fudrPaid: 'Fudr Paid',
    payToRestaurant: 'Pay to Restaurant',
    cashCard: 'Cash Card',
    ccTransactionReferenceId: 'Reference Id',
  },
  uploadBtnCTA: 'UPLOAD',
};
