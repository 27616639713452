import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash';

import asyncRoute from '../helpers/hoc/asyncRoute';

// routes import
import authenticationRoutes from '../routes/authentication';
import fudrRoutes from '../routes/fudr';
import restaurantRoutes from '../routes/restaurant';
import restaurantGroupRoutes from '../routes/restaurantGroup';
import { appName } from '../constants/globals';
import rootStore from '../store';

import { fetchMasterDataAction, markPersistorForPurgeAction } from '../actions/common';

import '../global/styles/common.scss';
import warningIcon from '../assets/images/icons/warning.svg';
import cancelIcon from '../assets/images/icons/cancel.svg';

// containers import

const Authentication = asyncRoute(() => import('./authentication'));
const Fudr = asyncRoute(() => import('./fudr'));
const Restaurant = asyncRoute(() => import('./restaurant'));
const RestaurantGroup = asyncRoute(() => import('./restaurantGroup'));
const ErrorPage = asyncRoute(() => import('../components/errorPage'));

class App extends Component {
  componentDidMount() {
    this.props.actions.fetchMasterData({ appName }); // fetch master Data on App mount
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps?.persistorMarkedForPurge, this.props?.persistorMarkedForPurge) &&
      this.props?.persistorMarkedForPurge
    ) {
      this.clearSessionAndRedirect();
    }
  }

  clearSessionAndRedirect = () => {
    const { persistor } = rootStore;
    // Clear the store being persisted from session storage
    persistor
      .purge()
      .then(() => {
        this.props.actions.markPersistorForPurge(false).then(() => {
          window.location.replace(window.location.origin);
        });
      })
      .catch((error) => {
        console.error('There was an error purging the store', error);
      });
  };

  render() {
    const { match } = this.props;
    return (
      <>
        <Switch>
          <Route
            exact
            path={`${match.path}`}
            render={() => <Redirect to={`${match.path}/${authenticationRoutes.authentication}`} />}
          />
          <Route
            path={`${match.path}/${authenticationRoutes.authentication}`}
            component={Authentication}
          />
          <Route path={`${match.path}/${fudrRoutes.fudr}`} component={Fudr} />
          <Route path={`${match.path}/${restaurantRoutes.restaurant}`} component={Restaurant} />
          <Route
            path={`${match.path}/${restaurantGroupRoutes.restaurantGroup}`}
            component={RestaurantGroup}
          />
          <Route component={ErrorPage} />
        </Switch>
        {/* Load any resources needed to be cached in case user agent goes offline */}
        <img src={warningIcon} style={{ display: 'none' }} alt="exception" />
        <img src={cancelIcon} style={{ display: 'none' }} alt="cancel" />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  persistorMarkedForPurge: state?.common?.persistorMarkedForPurge ?? false,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetchMasterData: fetchMasterDataAction,
      markPersistorForPurge: markPersistorForPurgeAction,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
