export const errorPageConfig = {
  title: 'Something is missing !!!',
  description: 'The page you’re looking for seems to be missing',
  dashboardDefaultCTABtn: 'BACK TO HOME',
  defaultCTABtn: 'GO BACK',
};

export const editRestaurantDetailPopupConfig = {
  title: 'Edit Outlet Details',
  actionBtn: 'UPDATE',
  backBtn: 'CANCEL',
  outletTitle: 'Outlet Name',
  label: {
    outletAddress: 'Outlet Address',
    googleLocation: 'Google Location',
    instagramLink: 'Instagram Link',
    facebookLink: 'Facebook Link',
    aboutOutlet: 'About Outlet',
  },
  placeholder: {
    instagramLink: "Add your outlet's Instagram link",
    facebookLink: "Add your outlet's Facebook link",
  },
  notProvided: 'not provided',
  tooltipcontentPartOne: 'Google location helps your guests to navigate to your outlet',
  tooltipcontentPartTwo:
    'Search for your Outlet on maps.google.com Copy the web address and paste it here.',
  toastMessage: 'updated successfully',
  genericErrorText: 'Please resolve the highlighted issues',
  errorMessage: {
    instagramLink: 'Please provide Instagram link',
    facebookLink: 'Please provide Facebook link',
    googleLocation: 'Please provide Google map link',
  },
};

export const offerStatus = {
  archived: 'ARCHIVED',
  paused: 'PAUSED',
  upcoming: 'UPCOMING',
  active: 'ACTIVE',
};

export const richTextEditor = {
  errorMessage: {
    charLimitErrorMesssage: 'Description should not be more than 1000 characters',
    linkErrorMessage: 'Please provide a valid link',
  },
};

export const soundPermissionsGuide = {
  instructions: {
    stepOneText: 'Step 1',
    stepTwoText: 'Step 2',
    stepThreeText: 'Step 3',
    informationTextForQuickFix: [
      'Sound notification enabled for the moment.',
      'To always allow sound notification please follow the steps below',
    ],
    stepFirstForPermanentFix:
      'Click on the Lock Icon beside the web address and click on Site settings.',
    stepSecondForPermanentFix:
      'In the list change the value of Sound and Notifications to ‘Allow’.',
    stepThirdForPermanentFix: 'Refresh the web page to apply the new settings',
  },
  browserPermsPopupObj: {
    title: 'Sound Notification',
    actionBtn: 'DONE',
  },
};

export const tableConfig = {
  custumPagination: {
    btnId: {
      firstPage: 'first-page',
      lastPage: 'last-page',
      nextPage: 'next-page',
      previousPage: 'previous-page',
    },
    showingOfText: 'Showing {firstItem} - {lastItem} of {totalItems}',
    tooltipText: {
      first: 'First Page',
      last: 'Last Page',
      next: 'Next Page',
      previous: 'Previous Page',
    },
  },
  filtersAppliedText: 'Filter(s) applied',
};

export const textField = {
  tooltipText: {
    clearSearch: 'Clear search',
  },
};

export const resources = {
  filter: {
    title: 'Filter by',
    resetFilter: 'RESET',
    footerContent: {
      actionBtn: 'APPLY',
      backBtn: 'BACK',
    },
    dateFilter: {
      placeholder: {
        startDate: 'From',
        endDate: 'To',
      },
      label: {
        anyDate: 'Any Date',
        thisWeek: 'This week',
        lastWeek: 'Last week',
        today: 'Today',
        thisMonth: 'This month',
        lastMonth: 'Last month',
        yesterday: 'Yesterday',
        custom: 'custom',
      },
    },
    numberFilter: {
      label: { GT: 'Greater than', ST: 'Smaller than', BTW: 'Between' },
      placeholders: {
        greaterThan: 'Enter number',
        smallerThan: 'Enter number',
        between: { start: 'Start', end: 'End' },
      },
    },
  },
  dateRange: {
    invalidRangeErrorText: 'Please provide a valid date range!',
  },
  rangeInputField: {
    invalidRangeErrorText: 'Please provide a valid date range!',
  },
};

export const mapResource = {
  viewMapCTABtn: 'VIEW MAP',
};
