// authentication level reducer

import {
  SIGN_IN_SUCCESS,
  SIGNUP_RESTAURANT,
  PSEUDO_SIGN_IN,
  PURGE,
} from '../../actions/authentication/actionTypes';

const initialState = { isPseudoSignIn: false };

const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN_SUCCESS:
      const { authData } = action;
      return {
        ...state,
        authData,
      };
    case SIGNUP_RESTAURANT:
      const { signUpRestaurantData } = action;
      return {
        ...state,
        restaurantData: signUpRestaurantData,
      };
    case PSEUDO_SIGN_IN:
      const { isPseudoSignIn } = action;
      return {
        ...state,
        isPseudoSignIn,
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};

export default authenticationReducer;
