// restaurant level reducer

import { get, union, isEmpty } from 'lodash';

import {
  FETCH_GUEST_ORDERS,
  SIGN_IN_SUCCESS,
  CHECK_GUEST_ORDERS,
  BEGIN_NOTIFICATION_LOADER,
  END_NOTIFICATION_LOADER,
  FETCH_ORDER_HISTORY,
  GET_ORDER_BY_ID_SUCCESS,
  GET_ORDER_BY_ID_FAILURE,
  UPDATE_ORDER,
  GET_RESTAURANT_MENU,
  GET_MENU_CUSTOMIZATIONS,
  TIMER_INITIATED,
  DISPATCH_ASSISTANCE_TIME_STAMP,
  GET_GUESTS_LIST,
  GET_GUEST_DETAILS,
  GET_GUEST_ORDERS,
  DISPATCH_RESTAURANT_SETTINGS,
  CLEAR_RESTAURANT_SETTINGS,
  UPDATE_RESTAURANT_ASSISTANCE_SERVICES,
  GET_CURRENT_ASSISTANCES,
  DISPATCH_ATTENDED_ASSISTANCES,
  GET_ORDER_SUMMARY_DATA,
  FETCH_MENU_ITEM_CUSTOMIZATIONS,
  FETCH_OFFERS,
  DISPATCH_OFFER_LIST,
  OFFER_DATA,
  FETCH_MENU_CATEGORIES,
  GET_LOCALITIES_BY_CITY,
  FETCH_RESTAURANT_TABLES,
  UPDATE_RESTAURANT_ADDITIONAL_CHARGES,
  ADD_RESTAURANT_ADDITIONAL_CHARGES,
  DELETE_RESTAURANT_ADDITIONAL_CHARGES,
  UPDATE_RESTAURANT_NOTIFICATION_EMAILS,
  FETCH_OFFER_GUESTS,
  GET_UPSELL_ITEM,
  GET_RESTAURANT_UPSELL,
  FETCH_RESTAURANT_CASH_CARD,
  FETCH_CARD_LIST,
  CLEAR_CARD_LIST,
  FETCH_CARD_DETAILS,
  CLEAR_CARD_DETAILS,
  FETCH_GUEST_CASH_CARD_TRANSACTIONS,
  CLEAR_GUEST_CASH_CARD_TRANSACTIONS,
  FETCH_ORDER_CANCELLATION_REASONS,
  CLEAR_ORDER_CANCELLATION_REASONS,
  FETCH_RESTAURANT_SUBSCRIPTION_DETAILS,
  CLEAR_RESTAURANT_SUBSCRIPTION_DETAILS,
  FETCH_RESTAURANT_SUBSCRIPTION_PLAN_HISTORY,
  CLEAR_RESTAURANT_SUBSCRIPTION_PLAN_HISTORY,
  FETCH_SAAS_PLANS,
  DISPATCH_PROGRESSIVE_WEB_APP_DATA,
  CLEAR_PROGRESSIVE_WEB_APP_DATA,
  FETCH_POS_SYNC_STATUS_BY_ORDER_IDS,
  PURGE,
  GET_ENTITY_MAPPING_DATA,
  CLEAR_ENTITY_MAPPING_DATA,
  CLEAR_POS_SYNC_STATUS_BY_ORDER_IDS,
  DISPATCH_POS_SYNC_STATUS,
  VALIDATE_DEAL_DATA,
  REDEEMED_DEAL_DATA,
  GET_RESTAURANT_POS_INTEGRATIONS,
  ClEAR_RESTAURANT_POS_INTEGRATIONS,
  CLEAR_RESTAURANT_INTEGRATIONS,
  FETCH_BULK_POS_SYNC_EVENTS,
  CLEAR_BULK_POS_SYNC_EVENTS,
  FETCH_EXTERNAL_POS_MENU_ITEMS,
} from '../../actions/restaurant/actionTypes';

const initialState = {};

const restaurantReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GUEST_ORDERS:
      const { order } = action;
      return {
        ...state,
        order,
      };
    case SIGN_IN_SUCCESS:
      const { authData } = action;
      return {
        ...state,
        authData,
      };
    case DISPATCH_RESTAURANT_SETTINGS:
      const { restaurantData } = action;
      return {
        ...state,
        restaurantData: {
          ...get(state, 'restaurantData', {}),
          ...restaurantData,
        },
      };
    case CLEAR_RESTAURANT_SETTINGS:
      return {
        ...state,
        restaurantData: {}, // empty restaurant data slice
      };
    case CHECK_GUEST_ORDERS:
      const { orderEvent } = action;
      return {
        ...state,
        orderEvent,
      };
    case BEGIN_NOTIFICATION_LOADER:
    case END_NOTIFICATION_LOADER:
      const { showNotificationLoader } = action;
      return {
        ...state,
        showNotificationLoader,
      };
    case FETCH_ORDER_HISTORY:
      const { orders } = action;
      return {
        ...state,
        orders,
      };
    case GET_ORDER_BY_ID_SUCCESS || GET_ORDER_BY_ID_FAILURE:
      const { orderById } = action;
      return {
        ...state,
        orderById,
      };
    case UPDATE_ORDER:
      const { updateOrderStatus } = action;
      return {
        ...state,
        updateOrderStatus,
      };
    case GET_RESTAURANT_MENU:
      const { restaurantMenu } = action;
      return {
        ...state,
        restaurantMenu: {
          ...restaurantMenu,
          sections: union(
            get(restaurantMenu, 'recommendedSections', []),
            get(restaurantMenu, 'sections', []),
          ),
        },
      };
    case GET_MENU_CUSTOMIZATIONS:
      const { menuCustomizations } = action;
      return {
        ...state,
        menuCustomizations,
      };
    case TIMER_INITIATED:
      const { isTimerInitiated } = action;
      return {
        ...state,
        isTimerInitiated,
      };

    case DISPATCH_ASSISTANCE_TIME_STAMP:
      const { assistanceTimeStamp } = action;
      return {
        ...state,
        assistanceTimeStamp,
      };
    case GET_GUESTS_LIST:
      const { guestsList } = action;
      return {
        ...state,
        guestsList,
      };
    case GET_GUEST_DETAILS:
      const { guestDetails } = action;
      return {
        ...state,
        guestDetails,
      };
    case GET_GUEST_ORDERS:
      const { guestOrders } = action;
      return {
        ...state,
        guestOrders,
      };

    case UPDATE_RESTAURANT_ASSISTANCE_SERVICES:
      const { restaurantAssistanceServices } = action;
      return {
        ...state,
        restaurantData: {
          ...get(state, 'restaurantData', {}),
          restaurantAssistanceServices,
        },
      };
    case GET_CURRENT_ASSISTANCES:
      const { assistancesData, assistancesCount } = action;
      return {
        ...state,
        assistancesData,
        assistancesCount,
      };
    case DISPATCH_ATTENDED_ASSISTANCES:
      const { attendedAssistancesArr } = action;
      return {
        ...state,
        attendedAssistancesArr,
      };
    case GET_ORDER_SUMMARY_DATA:
      const { orderSummaryData } = action;
      return {
        ...state,
        orderSummaryData,
      };
    case FETCH_MENU_ITEM_CUSTOMIZATIONS:
      const { customization } = action;
      return {
        ...state,
        customization,
      };
    case FETCH_OFFERS:
      const { offers } = action;
      return {
        ...state,
        offers: { ...offers },
      };
    case DISPATCH_OFFER_LIST:
      const { offerList } = action;
      return {
        ...state,
        offerList,
      };
    case OFFER_DATA:
      const { offerData } = action;
      return {
        ...state,
        offerData,
      };
    case FETCH_MENU_CATEGORIES:
      const { menuCategories } = action;
      return {
        ...state,
        menuCategories,
      };
    case GET_LOCALITIES_BY_CITY:
      const { localities } = action;
      return {
        ...state,
        localities,
      };
    case FETCH_RESTAURANT_TABLES:
      const { restaurantTables } = action;
      return {
        ...state,
        restaurantTables,
      };
    case UPDATE_RESTAURANT_ADDITIONAL_CHARGES:
      const { additionalCharge } = action;
      const prevAdditionalCharges = get(state, 'restaurantData.additionalCharges', []);
      const additionalChargeIndex = prevAdditionalCharges.findIndex(
        (charge) =>
          get(charge, 'additionalChargeId', '') === get(additionalCharge, 'additionalChargeId', ''),
      );
      if (additionalChargeIndex !== -1) {
        const updatedAdditionalCharges = [...state?.restaurantData?.additionalCharges];
        updatedAdditionalCharges[additionalChargeIndex] = additionalCharge;
        return {
          ...state,
          restaurantData: {
            ...state.restaurantData,
            additionalCharges: updatedAdditionalCharges,
          },
        };
      } else return state;
    case ADD_RESTAURANT_ADDITIONAL_CHARGES:
      const { additionalCharges: newAdditionalCharges } = action;
      return {
        ...state,
        restaurantData: {
          ...state.restaurantData,
          additionalCharges: state?.restaurantData?.additionalCharges.concat(newAdditionalCharges),
        },
      };
    case DELETE_RESTAURANT_ADDITIONAL_CHARGES:
      const { additionalChargeId } = action;
      const originalAdditionalCharges = get(state, 'restaurantData.additionalCharges', []);
      const deletableAdditionalChargeIndex = originalAdditionalCharges.findIndex(
        (charge) => get(charge, 'id', '') === additionalChargeId,
      );
      if (deletableAdditionalChargeIndex !== -1) {
        const updatedAdditionalCharges = [...state.restaurantData.additionalCharges];
        updatedAdditionalCharges.splice(deletableAdditionalChargeIndex, 1);
        return {
          ...state,
          restaurantData: {
            ...state.restaurantData,
            additionalCharges: updatedAdditionalCharges,
          },
        };
      } else return state;
    case UPDATE_RESTAURANT_NOTIFICATION_EMAILS:
      const { restaurantNotificationEmail } = action;
      return {
        ...state,
        restaurantData: {
          ...state.restaurantData,
          restaurantNotificationEmail,
        },
      };
    case FETCH_OFFER_GUESTS:
      const { offerGuests } = action;
      return {
        ...state,
        offerGuests,
      };
    case GET_UPSELL_ITEM:
      const { upsellItemList } = action;
      return {
        ...state,
        upsellItemList,
      };
    case GET_RESTAURANT_UPSELL:
      const { restaurantUpsell } = action;
      return {
        ...state,
        restaurantUpsell,
      };
    case FETCH_RESTAURANT_CASH_CARD:
      const { cashCard } = action;
      return {
        ...state,
        cashCard,
      };
    case FETCH_CARD_LIST:
      const { cardList } = action;
      return {
        ...state,
        cardList,
      };
    case CLEAR_CARD_LIST:
      return {
        ...state,
        cardList: {},
      };
    case FETCH_CARD_DETAILS:
      const { cardDetails } = action;
      return {
        ...state,
        cardDetails,
      };
    case CLEAR_CARD_DETAILS:
      return {
        ...state,
        cardDetails: {},
      };
    case FETCH_GUEST_CASH_CARD_TRANSACTIONS:
      const { guestCashCardTransactions } = action;
      return {
        ...state,
        guestCashCardTransactions,
      };
    case CLEAR_GUEST_CASH_CARD_TRANSACTIONS:
      return {
        ...state,
        guestCashCardTransactions: {},
      };
    case FETCH_ORDER_CANCELLATION_REASONS:
      const { orderCancellationReasons } = action;
      return {
        ...state,
        orderCancellationReasons,
      };
    case CLEAR_ORDER_CANCELLATION_REASONS:
      return {
        ...state,
        orderCancellationReasons: [],
      };
    case FETCH_RESTAURANT_SUBSCRIPTION_DETAILS:
      const { subscriptionDetails } = action;
      return {
        ...state,
        subscription: { ...(state?.subscription ?? {}), details: subscriptionDetails },
      };
    case CLEAR_RESTAURANT_SUBSCRIPTION_DETAILS:
      return {
        ...state,
        subscription: { ...(state?.subscription ?? {}), details: {} },
      };
    case FETCH_RESTAURANT_SUBSCRIPTION_PLAN_HISTORY:
      const { subscriptionPlanHistory } = action;
      return {
        ...state,
        subscription: { ...(state?.subscription ?? {}), history: subscriptionPlanHistory },
      };
    case CLEAR_RESTAURANT_SUBSCRIPTION_PLAN_HISTORY:
      return {
        ...state,
        subscription: { ...(state?.subscription ?? {}), history: {} },
      };
    case FETCH_SAAS_PLANS:
      const { saasPlans } = action;
      return {
        ...state,
        saasPlans,
      };
    case DISPATCH_PROGRESSIVE_WEB_APP_DATA:
      const { progressiveWebApp } = action;
      return {
        ...state,
        progressiveWebApp,
      };
    case CLEAR_PROGRESSIVE_WEB_APP_DATA:
      return {
        ...state,
        progressiveWebApp: {},
      };
    case FETCH_POS_SYNC_STATUS_BY_ORDER_IDS: {
      const { currentPOSSyncStatus } = action;
      return {
        ...state,
        currentPOSSyncStatus: currentPOSSyncStatus,
      };
    }
    case DISPATCH_POS_SYNC_STATUS: {
      const { currentPOSSyncStatus } = action;
      return {
        ...state,
        currentPOSSyncStatus,
      };
    }
    case CLEAR_POS_SYNC_STATUS_BY_ORDER_IDS:
      return {
        ...state,
        currentPOSSyncStatus: [],
      };
    case GET_ENTITY_MAPPING_DATA:
      const { entityMapping } = action;
      return {
        ...state,
        integrations: {
          ...(state?.integrations ?? {}),
          pos: {
            ...(state?.integrations?.pos ?? {}),
            entityMapping,
          },
        },
      };
    case CLEAR_ENTITY_MAPPING_DATA:
      return {
        ...state,
        integrations: {
          ...(state?.integrations ?? {}),
          pos: {
            ...(state?.integrations?.pos ?? {}),
            entityMapping: {},
          },
        },
      };
    case GET_RESTAURANT_POS_INTEGRATIONS:
      const { posIntegrationProperties } = action;
      const posSlice = !isEmpty(posIntegrationProperties)
        ? {
            pos: {
              ...(state?.integrations?.pos ?? {}),
              ...posIntegrationProperties,
            },
          }
        : {};

      return {
        ...state,
        integrations: {
          ...(state?.integrations ?? {}),
          ...posSlice,
        },
      };
    case ClEAR_RESTAURANT_POS_INTEGRATIONS:
      return {
        ...state,
        integrations: {
          ...(state?.integrations ?? {}),
          pos: {},
        },
      };
    case CLEAR_RESTAURANT_INTEGRATIONS:
      return {
        ...state,
        integrations: {},
      };
    case FETCH_BULK_POS_SYNC_EVENTS:
      const { bulkPOSSyncEvents } = action;
      return {
        ...state,
        bulkPOSSyncEvents,
      };
    case CLEAR_BULK_POS_SYNC_EVENTS:
      return {
        ...state,
        bulkPOSSyncEvents: {},
      };
    case PURGE:
      return initialState;
    //engage specific reducer
    case VALIDATE_DEAL_DATA:
      const { validateDeal } = action;
      return {
        ...state,
        validateDeal,
      };
    case REDEEMED_DEAL_DATA:
      const { redeemedDeal } = action;
      return {
        ...state,
        redeemedDeal,
      };
    case FETCH_EXTERNAL_POS_MENU_ITEMS:
      const { externalPOSMenuItems } = action;
      return {
        ...state,
        externalPOSMenuItems,
      };
    default:
      return state;
  }
};

export default restaurantReducer;
