import { isEmpty, get } from 'lodash';

import {
  fetchAllNotificationSoundsApi,
  fetchMasterDataApi,
  fetchAssistanceServicesMasterDataApi,
  fetchUpsellDiscountRangesApi,
  fetchStatesApi,
  fetchCitiesByStateApi,
  getTaxRatesApi,
} from '../../api';

import {
  BEGIN_AJAX_CALL,
  END_AJAX_CALL,
  API_ERROR_RESPONSE,
  DISPATCH_ALL_NOTIFICATION_SOUNDS,
  DISPATCH_CURRENT_TIME_STAMP,
  DISPATCH_ASSISTANCE_TIME_STAMP,
  FETCH_MASTER_DATA,
  FETCH_ASSISTANCE_SERVICES_MASTER_DATA,
  FETCH_UPSELL_DISCOUNT_RANGES,
  FETCH_STATES,
  GET_TAXRATES,
  MARK_PERSISTOR_FOR_PURGE,
} from './actionTypes';

export const beginAjaxCallAction = () => async (dispatch) => {
  dispatch({
    type: BEGIN_AJAX_CALL,
    showSpinner: true,
  });
};

export const endAjaxCallAction = () => async (dispatch) => {
  dispatch({
    type: END_AJAX_CALL,
    showSpinner: false,
  });
};

export const apiErrorResponseAction = (errorResponse) => async (dispatch) => {
  dispatch({
    type: API_ERROR_RESPONSE,
    errorResponse,
  });
  dispatch(endAjaxCallAction);
};

export const clearApiErrorResponseAction = () => (dispatch) => {
  dispatch({
    type: API_ERROR_RESPONSE,
    errorResponse: {},
  });
};

export const dispatchCurrentTimeStampAction =
  ({ timeStamp, userObjKey, assistanceTimeStamp }) =>
  async (dispatch) => {
    if (!isEmpty(userObjKey)) {
      let fudrAdminData = JSON.parse(localStorage.getItem('fudrAdminData'));
      if (!isEmpty(fudrAdminData)) {
        fudrAdminData[userObjKey].currentTimeStamp = timeStamp;
        fudrAdminData[userObjKey].assistanceTimeStamp = assistanceTimeStamp;
        localStorage.setItem('fudrAdminData', JSON.stringify(fudrAdminData));
      }
    }
    dispatch({
      type: DISPATCH_CURRENT_TIME_STAMP,
      currentTimeStamp: timeStamp,
    });
    dispatch({
      type: DISPATCH_ASSISTANCE_TIME_STAMP,
      assistanceTimeStamp,
    });
  };

export const fetchAllNotificationSoundsAction = () => async (dispatch) => {
  try {
    dispatch(beginAjaxCallAction());
    const allNotificationSoundsResponse = await fetchAllNotificationSoundsApi()
      .then((response) => {
        dispatch({
          type: DISPATCH_ALL_NOTIFICATION_SOUNDS,
          notificationSounds: get(response, 'data.notificationSound', []),
        });
      })
      .catch((errorResponse) => {
        dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
        return get(errorResponse, 'response', {});
      });

    return allNotificationSoundsResponse;
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(endAjaxCallAction());
  }
};

export const fetchMasterDataAction =
  ({ appName }) =>
  async (dispatch) => {
    dispatch(beginAjaxCallAction());
    fetchMasterDataApi({ appName })
      .then((response) => {
        dispatch({
          type: FETCH_MASTER_DATA,
          masterData: get(response, 'data', {}),
        });
      })
      .catch((error) => {
        dispatch(apiErrorResponseAction(get(error, 'response.data', {})));
      })
      .finally(() => dispatch(endAjaxCallAction()));
  };

export const fetchAssistanceServicesMasterDataAction =
  ({ accessToken }) =>
  async (dispatch) => {
    try {
      dispatch(beginAjaxCallAction());
      const assistanceServicesMasterDataAPIResponse = await fetchAssistanceServicesMasterDataApi({
        accessToken,
      })
        .then((response) => {
          dispatch({
            type: FETCH_ASSISTANCE_SERVICES_MASTER_DATA,
            assistanceServices: get(response, 'data.data', []),
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });

      return assistanceServicesMasterDataAPIResponse;
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(endAjaxCallAction());
    }
  };

export const fetchUpsellDiscountRangesAction = () => async (dispatch) => {
  try {
    dispatch(beginAjaxCallAction());
    let response = await fetchUpsellDiscountRangesApi()
      .then((response) => {
        dispatch({
          type: FETCH_UPSELL_DISCOUNT_RANGES,
          upsellDiscountRanges: get(response, 'data.upsellDiscountRange', []),
        });
        return response;
      })
      .catch((errorResponse) => {
        dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
        return get(errorResponse, 'response', {});
      });
    return response;
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(endAjaxCallAction());
  }
};

export const fetchStatesAction = () => async (dispatch) => {
  try {
    dispatch(beginAjaxCallAction());
    let stateResponse = await fetchStatesApi()
      .then((response) => {
        dispatch({
          type: FETCH_STATES,
          states: get(response, 'data', []),
        });
        return response;
      })
      .catch((errorResponse) => {
        dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
        return get(errorResponse, 'response', {});
      });
    return stateResponse;
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(endAjaxCallAction());
  }
};

export const fetchCitiesByStateAction = (stateId) => async (dispatch) => {
  try {
    dispatch(beginAjaxCallAction());
    let citiesResponse = await fetchCitiesByStateApi({ stateId })
      .then((response) => {
        return response;
      })
      .catch((errorResponse) => {
        dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
        return get(errorResponse, 'response', {});
      });
    return citiesResponse;
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(endAjaxCallAction());
  }
};

/**
 * Action to - Get tax rates
 */
export const getTaxRatesAction = () => async (dispatch) => {
  try {
    dispatch(beginAjaxCallAction());
    let response = await getTaxRatesApi()
      .then((response) => {
        dispatch({
          type: GET_TAXRATES,
          taxRates: get(response, 'data.taxrates', []),
        });
        return response;
      })
      .catch((errorResponse) => {
        dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
        return get(errorResponse, 'response', {});
      });
    return response;
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(endAjaxCallAction());
  }
};

/**
 * Action to - Fetch Restaurant Tables
 */

export const markPersistorForPurgeAction = (persistorMarkedForPurge) => async (dispatch) => {
  dispatch({
    type: MARK_PERSISTOR_FOR_PURGE,
    persistorMarkedForPurge,
  });
};
