import { resources } from '../resources';
// Get runtime environment variables from '_env' object attached to 'window' global object
const {
  API_URL,
  GUEST_APP_DOMAIN,
  GOOGLE_MAP_API_KEY,
  STORE_MAX_AGE,
  USER_ACTIVITY_INTERVAL,
  PRIVACY_POLICY_LINK,
  TNC_LINK,
  STORE_EXPIRY_ENABLED,
  ENGAGE_APP_DOMAIN,
  ENABLE_DIST_PLANS_FOR_SUGGEST_CAMPAIGN,
  SUPPORT_EMAIL,
  RECAPTCHA_SITE_KEY,
} = '_env' in window && window._env;

export const publicUrl = GUEST_APP_DOMAIN;

export const engageAppUrl = ENGAGE_APP_DOMAIN;

export const CONTEXT = '/dashboard';

export const apiBaseUrl = `${API_URL}/restaurant-service`;

export const googleApiKey = GOOGLE_MAP_API_KEY;

export const storeMaxAge = STORE_MAX_AGE && JSON.parse(STORE_MAX_AGE); // in ms

export const userActivityInterval = USER_ACTIVITY_INTERVAL && JSON.parse(USER_ACTIVITY_INTERVAL);

export const userActivityTrackingDebounceTime = 5000; // in ms

export const storeExpiryEnabled = STORE_EXPIRY_ENABLED && JSON.parse(STORE_EXPIRY_ENABLED);

export const operatorArr = [
  { code: 'GT', title: 'greater than' },
  { code: 'LT', title: 'lower than' },
  { code: 'NET', title: 'not equals to' },
  { code: 'GTE', title: 'greater than equals to' },
  { code: 'LTE', title: 'lower than equals to' },
  { code: 'ET', title: 'equals to' },
];

export const greaterThanEqualToCode = 'GTE';
export const lowerThanEqualToCode = 'LTE';
export const equalToCode = 'ET';

export const sortOrder = {
  asc: 'ASC',
  desc: 'DESC',
};

export const editRestaurantDetails = {
  maxCharCount: 1000,
};

export const restaurantDataDepthParams = {
  config: 'con',
  tables: 'loc',
  delivery: 'del',
  additionalCharges: 'rac',
  assistnaceService: 'ras',
  workhours: 'rwh',
  serviceTypes: 'rst',
  phoneNumbers: 'rpn',
  notificationEmails: 'rne',
  default: 'all',
};

export const guestDataDepthParams = {
  guestCashCard: 'gcc',
  guestAddresses: 'ga',
  guestVehicles: 'gv',
  guestOrderStats: 'gos',
};

export const kotTemplateTypes = {
  bhtV1: 'bht-1.0',
};

export const currencies = {
  INR: { text: 'Rs.', symbol: '₹' },
};

export const analyticsVendorType = {
  GTM: 'GTM',
};

export const acceptableImageTypes = ['jpeg', 'png', 'svg', 'jpg', 'gif'];

export const notificationEmailTypes = {
  default: 'DEFAULT',
};

export const eventType = {
  serverSideEvent: 'sse',
  polling: 'poll',
};

export const defaultPaletteColors = [
  '#ff0000', // red
  '#ff5900', // orange
  '#ff8f00', // dark orange
  '#ffc600', // golden poppy
  '#00a3c2', // pacific blue
  '#00b200', // green
  '#72cc00', // lawn green
  '#fafd00', // yellow
  '#005db7', // cobalt blue
  '#0f12a8', // ultramarine blue
  '#6c0aa8', // indigo
];

export const defaultPaginationState = {
  currentPage: 0,
  prevPage: -1,
  nextPage: 1,
};

export const loaders = {
  threeDots: {
    default: {},
    inverted: {
      color: '#191919',
      size: '16px',
    },
  },
};

const dateFiltersType = resources.filter.dateFilter.label;
export const dateFilterConfig = {
  filters: {
    default: { code: 'DF', title: dateFiltersType.anyDate },
    custom: { code: 'C', title: dateFiltersType.custom },
    today: { code: 'TD', title: dateFiltersType.today },
    thisWeek: { code: 'TW', title: dateFiltersType.thisWeek },
    thisMonth: { code: 'TM', title: dateFiltersType.thisMonth },
    yesterday: { code: 'YD', title: dateFiltersType.yesterday },
    lastWeek: { code: 'LW', title: dateFiltersType.lastWeek },
    lastMonth: { code: 'LM', title: dateFiltersType.lastMonth },
  },

  filterDateFormat: 'DD MMM YYYY',
};

export const transactionCategories = {
  credit: 'C',
  debit: 'D',
};

export const transactionCategoryUIAttributesGroup = {
  [transactionCategories.credit]: { prefix: '+' },
  [transactionCategories.debit]: { prefix: '-' },
};

export const transactionTypes = {
  add: 'RECH',
  refund: 'REFU',
  expired: 'EXP',
  payment: 'ORDR',
  reward: 'REW',
};

export const dateRangeBeforeDaysExtent = 365; // in days

export const cashCardUnitPointMonetaryFactor = 1;

export const refundTypesConfig = {
  prepaid: 'PRE_PAYMENT',
  loyaltyRefundTypes: { CASHCARD: 'cc' },
};

export const refundStatus = {
  success: 'SUCCESS',
  initial: 'INITIAL',
  pending: 'PENDING',
  failed: 'FAILED',
};

export const upsellConfig = {
  minCartAcceptableValue: 1,
  maxCartAcceptableValue: 99999,
};

export const genericErrorCodeConfig = {
  msgServiceTimeoutError: 'MSG91_TIMEOUT_ERR',
};

export const mapConfig = {
  circleRadiusUnit: 'km',
  minCircleRadiusInMeter: 1000,
  defaultMapZoom: 11,
  mapMinZoom: 4,
  mapMaxZoom: 16,
  mapMarkerIconUrl: 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2_hdpi.png',
  gestureHandling: 'greedy',
  meterInOneKm: 1000,
  maxDistanceBtwTwoCoordinates: 200,
  defaultCenter: { lat: 20.5937, lng: 78.9629 },
};

export const toastDisplayTime = 3000; // in ms

export const tagComponentUIAttributes = {
  size: {
    mini: 'mini',
    tiny: 'tiny',
    small: 'small',
    medium: 'medium',
    large: 'large',
    big: 'big',
    huge: 'huge',
    massive: 'massive',
  },
};

export const attachedLabelPosition = {
  end: 'end',
  start: 'start',
};

export const dineInModes = ['DI', 'RO'];

export const uiNavigationDirections = {
  up: 'up',
  down: 'down',
  left: 'left',
  right: 'right',
};

export const privacyPolicyLinkConfig = PRIVACY_POLICY_LINK ?? '';

export const tncLinkConfig = TNC_LINK ?? '';

export const initialMultipleDeliveryValues = {
  rangeMinAmount: 0,
  rangeMaxAmount: 101,
  rangeMinDistance: 0,
  rangeMaxDistance: 201,
  minCharge: 0,
};

export const numberFilterType = {
  greaterThan: 'GT',
  smallerThan: 'ST',
  between: 'BTW',
};

export const enableDistributionPlansForSuggestCampaigns = JSON.parse(
  ENABLE_DIST_PLANS_FOR_SUGGEST_CAMPAIGN,
);

export const supportEmail = SUPPORT_EMAIL ?? '';

export const recaptchaSiteKey = RECAPTCHA_SITE_KEY ?? '';
